import {
  Stack,
  Typography,
  Grid,
  IconButton,
  Box,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  CircularProgress
} from "@mui/material";
import React, { useEffect, useState } from "react";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { NavLink } from "react-router-dom";
import {
  StyledTableCell,
  StyledTableRow,
  TableCellSummary,
  StyledTableContainer,
} from "../../components/Table";
import { GreenBox } from "../../components/Chip";
import { useNavigate, useLocation } from "react-router-dom";
import { GetMasterTransactionsOrderDetails } from "../../services/dashboard.services";
import { LoadingBox } from "../../components/Box";
import { formatCurrency } from "../../utils/utilityFunctions";
import moment from "moment";

export default function Summary() {
  let data = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [masterTransactionsDetails, setMasterTransactionsDetails] = useState(null);

  useEffect(() => {
    (async () => {
      if (data.state === null) {
        navigate("/dashboard");
      }
      setIsLoading(false);
      try {
        await GetMasterTransactionsOrderDetails(data.state.orderId).then((data) => {
          setIsLoading(true);
          setMasterTransactionsDetails(data);
        });
        setIsLoading(false);
      } catch (error) {
        throw error;
      }
    })();
    // eslint-disable-next-line
  }, [data.state])

  if (!masterTransactionsDetails || isLoading)
    return (
      <LoadingBox>
        <CircularProgress />
      </LoadingBox>
    );

  
  return (
    <div>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <NavLink to="/dashboard" state={{ pageTitle: "Client Information" }}>
            <IconButton>
              <ArrowBackIosIcon />
            </IconButton>
          </NavLink>
          <Typography variant="h3">
            Summary of Order ID {data.state.orderId}
          </Typography>
        </Stack>
      </Stack>
      <br></br>

      <Grid container spacing={2}>
        <Grid item xs={6} sx={{ pr: 2 }}>
          <Box
            sx={{
              background: "#FFFFFF",
              boxShadow: "0px 2px 8px rgba(3, 40, 120, 0.18)",
              borderRadius: "18px",
              height: "100%",
            }}
            p={2}
          >
            <Stack direction="row" spacing={1}>
              <Typography variant="h4" color="primary.Primary_Blue">
                Factored Data
              </Typography>
            </Stack>
            <br></br>
            <TableContainer>
              <Table>
                <TableBody>
                  <StyledTableRow>
                    <TableCellSummary>Date</TableCellSummary>
                    <TableCellSummary sx={{ fontWeight: 400 }}>
                    {moment(masterTransactionsDetails.factoredData.factoredDate).format("MMM D, y")}
                    </TableCellSummary>
                  </StyledTableRow>
                  <StyledTableRow>
                    <TableCellSummary>Order ID</TableCellSummary>
                    <TableCellSummary sx={{ fontWeight: 400 }}>
                    {masterTransactionsDetails.factoredData.orderId}
                    </TableCellSummary>
                  </StyledTableRow>
                  <StyledTableRow>
                    <TableCellSummary>Description</TableCellSummary>
                    <TableCellSummary sx={{ fontWeight: 400 }}>
                    {masterTransactionsDetails.factoredData.description}
                    </TableCellSummary>
                  </StyledTableRow>
                  <StyledTableRow>
                    <TableCellSummary>Customer</TableCellSummary>
                    <TableCellSummary sx={{ fontWeight: 400 }}>
                    {masterTransactionsDetails.factoredData.customerName}
                    </TableCellSummary>
                  </StyledTableRow>
                  <StyledTableRow>
                    <TableCellSummary>Marketplace</TableCellSummary>
                    <TableCellSummary sx={{ fontWeight: 400 }}>
                    {masterTransactionsDetails.factoredData.marketplace}
                    </TableCellSummary>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{
              background: "#FFFFFF",
              boxShadow: "0px 2px 8px rgba(3, 40, 120, 0.18)",
              borderRadius: "18px",
              height: "100%",
            }}
            p={2}
          >
            <Stack direction="row" spacing={1}>
              <Typography variant="h4" color="primary.Primary_Blue">
                Platform and Other Charges
              </Typography>
            </Stack>
            <br></br>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <StyledTableCell>
                      <Typography variant="body1">Product Tax</Typography>
                    </StyledTableCell>
                    <StyledTableCell sx={{ fontWeight: 400 }} align="right">
                    {formatCurrency(masterTransactionsDetails.platformCharges.productTax)}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell>
                      <Typography variant="body1">Platform Charges</Typography>
                    </StyledTableCell>
                    <StyledTableCell sx={{ fontWeight: 400 }} align="right">
                    {formatCurrency(masterTransactionsDetails.platformCharges.platformCharges)}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell>
                      <Typography variant="body1">Other Charges</Typography>
                    </StyledTableCell>
                    <StyledTableCell sx={{ fontWeight: 400 }} align="right">
                    {formatCurrency(masterTransactionsDetails.platformCharges.otherCharges)}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell sx={{ fontWeight: 700 }} align="right">
                    {formatCurrency(masterTransactionsDetails.platformCharges.otherCharges + masterTransactionsDetails.platformCharges.platformCharges + masterTransactionsDetails.platformCharges.productTax)}
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
      <br></br>
      <Box
        sx={{
          background: "#FFFFFF",
          boxShadow: "0px 2px 8px rgba(3, 40, 120, 0.18)",
          borderRadius: "18px",
          height: "100%",
        }}
        p={2}
      >
        <Stack direction="row" spacing={1}>
          <Typography variant="h4" color="primary.Primary_Blue">
            Transaction Summary
          </Typography>
        </Stack>
        <br></br>
        <StyledTableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#F6F6F6" }}>
                <StyledTableCell align="left">Purchase Date</StyledTableCell>
                <StyledTableCell align="left">Gross Value</StyledTableCell>
                <StyledTableCell align="left">Charges</StyledTableCell>
                <StyledTableCell align="left">Net Value</StyledTableCell>
                <StyledTableCell align="left">Purchase Amount</StyledTableCell>
                <StyledTableCell align="left">Revenue</StyledTableCell>
                <StyledTableCell align="left">Agency Fee</StyledTableCell>
                <StyledTableCell align="left">
                  Marketplace Payment
                </StyledTableCell>
                <StyledTableCell align="left">Incentive Fee</StyledTableCell>
                <StyledTableCell align="left">GL Impact</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="left" variant="body2">
                {moment(masterTransactionsDetails.transactionSummary.purchaseDate).format("MMM D, y")}
                </TableCell>

                <TableCell align="left" variant="body2">
                {formatCurrency(masterTransactionsDetails.transactionSummary.grossAmount)}
                </TableCell>
                <TableCell align="left" variant="body2">
                {formatCurrency(masterTransactionsDetails.transactionSummary.totalCharges)}
                </TableCell>
                <TableCell align="left" variant="body2">
                {formatCurrency(masterTransactionsDetails.transactionSummary.netAmount)}
                </TableCell>
                <TableCell align="left" variant="body2">
                {formatCurrency(masterTransactionsDetails.transactionSummary.purchaseAmount)}
                </TableCell>
                <TableCell align="left" variant="body2">
                {formatCurrency(masterTransactionsDetails.transactionSummary.vePayRevenueAmount)}
                </TableCell>
                <TableCell align="left" variant="body2">
                {formatCurrency(masterTransactionsDetails.transactionSummary.agencyFeeAmount)}
                </TableCell>
                <TableCell align="left" variant="body2">
                {formatCurrency(masterTransactionsDetails.transactionSummary.marketplacePaymentAmount)}
                </TableCell>
                <TableCell align="left" variant="body2">
                {formatCurrency(masterTransactionsDetails.transactionSummary.incentiveFeeAmount)}
                </TableCell>
                <TableCell align="left" variant="body2">
                  View
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Box>
      <br></br>
      <Box
        sx={{
          background: "#FFFFFF",
          boxShadow: "0px 2px 8px rgba(3, 40, 120, 0.18)",
          borderRadius: "18px",
          height: "100%",
        }}
        p={2}
      >
        <Stack direction="row" spacing={1}>
          <Typography variant="h4" color="primary.Primary_Blue">
            Stock Details
          </Typography>
        </Stack>
        <br></br>
        <StyledTableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#F6F6F6" }}>
                <StyledTableCell align="left">Purchase Date</StyledTableCell>
                <StyledTableCell align="left">Order Item ID</StyledTableCell>
                <StyledTableCell align="left">SKU</StyledTableCell>
                <StyledTableCell align="left">ASIN</StyledTableCell>
                <StyledTableCell align="left">Product Name</StyledTableCell>
                <StyledTableCell align="left">Stock Status</StyledTableCell>
                <StyledTableCell align="left">Quantity</StyledTableCell>
                <StyledTableCell align="left">Unit Price</StyledTableCell>
                <StyledTableCell align="left">Total Amount</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {masterTransactionsDetails.stockDetails
                .sort(
                  (a, b) =>
                    parseFloat(b.purchaseDate) - parseFloat(a.purchaseDate)
                ).map((data, index) => (
              <TableRow>
                <TableCell align="left" variant="body2">
                {moment(data.purchaseDate).format("MMM D, y")}
                </TableCell>

                <TableCell align="left" variant="body2">
                  {data.orderItemId}
                </TableCell>
                <TableCell align="left" variant="body2">
                {data.itemSku}
                </TableCell>
                <TableCell align="left" variant="body2">
                {data.itemAsin}
                </TableCell>
                <TableCell align="left" variant="body2">
                {data.productName}
                </TableCell>
                <TableCell align="left" variant="body2">
                {data.stockItemStatus}
                </TableCell>
                <TableCell align="left" variant="body2">
                {data.itemQty}
                </TableCell>
                <TableCell align="left" variant="body2">
                {formatCurrency(data.unitPrice)}
                </TableCell>
                <TableCell align="left" variant="body2">
                {formatCurrency(data.totalAmount)}
                </TableCell>
              </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Box>
      <br></br>
      <Box
        sx={{
          background: "#FFFFFF",
          boxShadow: "0px 2px 8px rgba(3, 40, 120, 0.18)",
          borderRadius: "18px",
          height: "100%",
        }}
        p={2}
      >
        <Stack direction="row" spacing={1}>
          <Typography variant="h4" color="primary.Primary_Blue">
            Shipping Details
          </Typography>
        </Stack>
        <br></br>
        <StyledTableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#F6F6F6" }}>
                <StyledTableCell align="left">Shipped Date</StyledTableCell>
                <StyledTableCell align="left">Carrier</StyledTableCell>
                <StyledTableCell align="left">Shipping ID</StyledTableCell>
                <StyledTableCell align="left">Shipping Status</StyledTableCell>
                <StyledTableCell align="left">Tracking ID</StyledTableCell>
                <StyledTableCell align="left">GL Impact</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="left" variant="body2">
                {moment(masterTransactionsDetails.orderShipment.shippedDate).format("MMM D, y")}
                </TableCell>

                <TableCell align="left" variant="body2">
                  {masterTransactionsDetails.orderShipment.carrierName}
                </TableCell>
                <TableCell align="left" variant="body2">
                  {masterTransactionsDetails.orderShipment.shippingId}
                </TableCell>
                <TableCell align="left" variant="body2">
                  <GreenBox>{masterTransactionsDetails.orderShipment.orderShipmentStatus}</GreenBox>
                </TableCell>
                <TableCell align="left" variant="body2">
                  {masterTransactionsDetails.orderShipment.orderTrackingId}
                </TableCell>
                <TableCell align="left" variant="body2">
                  View
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Box>
      <br></br>
      <Box
        sx={{
          background: "#FFFFFF",
          boxShadow: "0px 2px 8px rgba(3, 40, 120, 0.18)",
          borderRadius: "18px",
          height: "100%",
        }}
        p={2}
      >
        <Stack direction="row" spacing={1}>
          <Typography variant="h4" color="primary.Primary_Blue">
            Payment Recieved From Marketplace
          </Typography>
        </Stack>
        <br></br>
        <StyledTableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#F6F6F6" }}>
                <StyledTableCell align="left">Deposit Date</StyledTableCell>
                <StyledTableCell align="left">Transfer ID</StyledTableCell>
                <StyledTableCell align="left">Shipment Group</StyledTableCell>
                <StyledTableCell align="left">Trace ID</StyledTableCell>
                <StyledTableCell align="left">Transferred To</StyledTableCell>
                <StyledTableCell align="left">Amount</StyledTableCell>
                <StyledTableCell align="left">GL Impact</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="left" variant="body2">
                {moment(masterTransactionsDetails.marketplacePayment.paymentReceivedDate).format("MMM D, y")}
                </TableCell>

                <TableCell align="left" variant="body2">
                {masterTransactionsDetails.marketplacePayment.transferId}
                </TableCell>
                <TableCell align="left" variant="body2">
                {masterTransactionsDetails.marketplacePayment.shipmentGroupId}
                </TableCell>
                <TableCell align="left" variant="body2">
                {masterTransactionsDetails.marketplacePayment.traceId}
                </TableCell>
                <TableCell align="left" variant="body2">
                {masterTransactionsDetails.marketplacePayment.payAccountName}/{masterTransactionsDetails.marketplacePayment.payAccountType}
                </TableCell>
                <TableCell align="left" variant="body2">
                {formatCurrency(data.unitPrice)}
                </TableCell>
                <TableCell align="left" variant="body2">
                  View
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Box>
      <br></br>
      <Box
        sx={{
          background: "#FFFFFF",
          boxShadow: "0px 2px 8px rgba(3, 40, 120, 0.18)",
          borderRadius: "18px",
          height: "100%",
        }}
        p={2}
      >
        <Stack direction="row" spacing={1}>
          <Typography variant="h4" color="danger.Danger_Dk">
            Return Details
          </Typography>
        </Stack>
        <br></br>
        <StyledTableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#F6F6F6" }}>
                <StyledTableCell align="left">Date</StyledTableCell>
                <StyledTableCell align="left">Order ID</StyledTableCell>
                <StyledTableCell align="left">Gross Value</StyledTableCell>
                <StyledTableCell align="left">Charges</StyledTableCell>
                <StyledTableCell align="left">Net Value</StyledTableCell>
                <StyledTableCell align="left">Delivery Status</StyledTableCell>
                <StyledTableCell align="left">Payment Status</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="left" variant="body2"></TableCell>
                <TableCell align="left" variant="body2"></TableCell>
                <TableCell align="left" variant="body2"></TableCell>
                <TableCell align="left" variant="body2"></TableCell>
                <TableCell align="left" variant="body2"></TableCell>
                <TableCell align="left" variant="body2"></TableCell>
                <TableCell align="left" variant="body2"></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Box>
    </div>
  );
}
