import { BASE_URL } from "../utils/constants";
import handler from "../utils/handler";
import { clearAllLocalStorage } from "../utils/localStorageManager";

export const PostBusinessInformation = async(value) => {
    const accessToken = sessionStorage.getItem('accessToken')
    try {
        const headers = {
            "Content-Type": "application/json",
        }
        
        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }

        let response = await fetch(`${BASE_URL}/core/businesses`, {
            method: "POST",
            headers,
            body: JSON.stringify(value)
        });

        if (response.status === 401) {
            clearAllLocalStorage();
        };

        const data = await response.json();

        return {response, data};

    } catch (e) {
        throw e;
    }
}

export const GetBusinessList = async(value) => {
    const accessToken = sessionStorage.getItem('accessToken')
    try {

        const headers = {
            "Content-Type": "application/json",
        }

        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }

        let response = await fetch(`${BASE_URL}/admin/dashboard/businessList`, {
            method: "GET",
            headers,
        });

        if (response.status === 401) {
            clearAllLocalStorage();
        };

        let result = handler(response)

        return result;

    } catch (e) {
        throw e;
    }
}
