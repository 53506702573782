import React from "react";
import { useState } from "react";
import { Box, Tab, Typography } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ClientInformation from "./ClientInformation";
import OnboardingRequests from "./OnboardingRequests";
import { NavLink } from "react-router-dom";
import { RoundEdgeContainedButton } from "../../components/Buttons";
import ControlPointIcon from "@mui/icons-material/ControlPoint";

export default function Dashboard() {
  const [tabValue, setTabValue] = useState("1");

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={tabValue}>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider", display: "flex" }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab
                label={<Typography variant="h3">Client Information</Typography>}
                value="1"
                sx={{ textTransform: "capitalize" }}
              />
              <Tab
                label={
                  <Typography variant="h3">Onboarding Requests</Typography>
                }
                value="2"
                sx={{ textTransform: "capitalize" }}
              />
            </TabList>
            <NavLink
              to="/client-admin/client-information/new-client"
              style={{ textDecoration: "none" }}
            >
              <RoundEdgeContainedButton
                variant="contained"
                startIcon={<ControlPointIcon />}
              >
                New Client
              </RoundEdgeContainedButton>
            </NavLink>
          </Box>
          <TabPanel value="1">
            <ClientInformation />
          </TabPanel>
          <TabPanel value="2">
            <OnboardingRequests />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}
