import * as React from "react";
import { useState } from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Stack,
  Snackbar,
  Box,
} from "@mui/material";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  StyledTextBox,
  StyledSelectTextBox,
} from "../../../components/TextBox";
import { ContainedSearchButton } from "../../../components/Buttons";
import MenuItem from "@mui/material/MenuItem";
import CancelOutlined from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineOutlined from "@mui/icons-material/CheckCircleOutlineOutlined";
// import { PostNewClient } from "../../../services/client.services";
// import { PostBusinessInformation } from "../../../services/business.services";
// import { PostBankInformation } from "../../../services/seller.services";
// import { useNavigate } from "react-router-dom";

export default function BankInformation() {
  const [values, setValues] = useState({
    accountHolderName: "",
    accountName: "",
    accountNo: "",
    bankCode: "no data",
    bankName: "",
    bankAddress: "",
    bankCity: "",
    branchCode: "",
    bankState: "",
    businessEncryptedId: "",
    country: "United States",
    currency: "no data",
    payAccountType: "CHECKING",
    routingNo: "",
    swiftIbanNo: "",
    zipCode: "",
  });
  // const navigate = useNavigate();
  const [errors, setErrors] = useState({
    accountHolderName: { error: false, message: "" },
    accountName: { error: false, message: "" },
    accountNo: { error: false, message: "" },
    bankName: { error: false, message: "" },
    bankAddress: { error: false, message: "" },
    bankCity: { error: false, message: "" },
    branchCode: { error: false, message: "" },
    bankState: { error: false, message: "" },
    routingNo: { error: false, message: "" },
    swiftIbanNo: { error: false, message: "" },
    zipCode: { error: false, message: "" },
  });

  const [alert, setAlert] = useState({
    states: false,
    color: "",
    message: "",
    type: false,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    let error = false;

    const requiredFields = Object.keys(errors);

    for (let i = 0; i < requiredFields.length; i++) {
      const field = requiredFields[i];
      if (values[field] === "") {
        
        let errorMessage;
        switch (field) {
          case "accountHolderName":
            errorMessage = "Account holder name is required";
            break;
          case "accountName":
            errorMessage = "Account name is required";
            break;
          case "accountNo":
            errorMessage = "Account number is required";
            break;
          case "bankName":
            errorMessage = "Bank name is required";
            break;
          case "bankAddress":
            errorMessage = "Branch address is required";
            break;
          case "bankCity":
            errorMessage = "Branch city is required";
            break;
          case "branchCode":
            errorMessage = "Branch code is required";
            break;
          case "bankState":
            errorMessage = "Branch state is required";
            break;
          case "routingNo":
            errorMessage = "Routing number is required";
            break;
          case "swiftIbanNo":
            errorMessage = "Swift/IBAN number is required";
            break;
          case "zipCode":
            errorMessage = "Zip code is required";
            break;
          default:
            errorMessage = "This field is required";
        }
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: { error: true, message: errorMessage },
        }));
        error = true;
      }
    }

    if (!error) {
      const clientInfo = JSON.parse(localStorage.getItem("clientInfo"));
      const businessInfo = JSON.parse(localStorage.getItem("businessInfo"));

      const allInfo = {
        bank: values,
        business: businessInfo,
        client: clientInfo,
      };

      console.log(allInfo);

      // PostNewClient(clientInfo).then((clientResponse) => {
      //   if(clientResponse.response.ok){

      //     setAlert({
      //       states: true,
      //       color: "#00AD47",
      //       message: "Client added Successfuly",
      //       type: true,
      //     });

      //     setTimeout(() => navigate("/client-information"), 2000)

      //   }else{
      //     setAlert({
      //       states: true,
      //       color: "#F33535",
      //       message: "Error related to Client information",
      //       type: false,
      //     });
      //   }
      // })
    }
  };

  return (
    <div>
      <Accordion expanded>
        <AccordionSummary
          sx={{
            backgroundColor: "#F1F5FC",
            border: "1px solid #D6D9E1",
            borderRadius: "8px 8px 0px 0px",
          }}
          // expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            {values.accountName === "" ? "Account Name" : values.accountName}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <br></br>
          <Grid container justifyContent="flex-start">
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                  Account Name
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledTextBox
                  fullWidth
                  value={values.accountName}
                  onChange={(e) =>
                    setValues({ ...values, accountName: e.target.value })
                  }
                />
                {errors.accountName.error ? (
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    pt={0.5}
                  >
                    <CancelOutlined
                      sx={{
                        fontSize: "0.875em",
                        color: "red",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setErrors({
                          ...errors,
                          accountName: { error: false, message: "" },
                        })
                      }
                    />
                    <Typography variant="body3" color="red" textAlign="left">
                      {errors.accountName.message}
                    </Typography>
                  </Stack>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <Grid xs={1} />
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                  Bank Name
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledTextBox
                  fullWidth
                  value={values.bankName}
                  onChange={(e) =>
                    setValues({ ...values, bankName: e.target.value })
                  }
                />
                {errors.bankName.error ? (
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    pt={0.5}
                  >
                    <CancelOutlined
                      sx={{
                        fontSize: "0.875em",
                        color: "red",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setErrors({
                          ...errors,
                          bankName: { error: false, message: "" },
                        })
                      }
                    />
                    <Typography variant="body3" color="red" textAlign="left">
                      {errors.bankName.message}
                    </Typography>
                  </Stack>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Grid>
          <br></br>
          <Grid container justifyContent="flex-start">
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                  Account Number
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledTextBox
                  fullWidth
                  value={values.accountNo}
                  onChange={(e) =>
                    setValues({ ...values, accountNo: e.target.value })
                  }
                />
                {errors.accountNo.error ? (
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    pt={0.5}
                  >
                    <CancelOutlined
                      sx={{
                        fontSize: "0.875em",
                        color: "red",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setErrors({
                          ...errors,
                          accountNo: { error: false, message: "" },
                        })
                      }
                    />
                    <Typography variant="body3" color="red" textAlign="left">
                      {errors.accountNo.message}
                    </Typography>
                  </Stack>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <Grid xs={1} />
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                  Account Holder Name
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledTextBox
                  fullWidth
                  value={values.accountHolderName}
                  onChange={(e) =>
                    setValues({ ...values, accountHolderName: e.target.value })
                  }
                />
                {errors.accountHolderName.error ? (
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    pt={0.5}
                  >
                    <CancelOutlined
                      sx={{
                        fontSize: "0.875em",
                        color: "red",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setErrors({
                          ...errors,
                          accountHolderName: { error: false, message: "" },
                        })
                      }
                    />
                    <Typography variant="body3" color="red" textAlign="left">
                      {errors.accountHolderName.message}
                    </Typography>
                  </Stack>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Grid>
          <br></br>
          <Grid container justifyContent="flex-start">
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                  SWIFT/IBAN Number
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledTextBox
                  fullWidth
                  value={values.swiftIbanNo}
                  onChange={(e) =>
                    setValues({ ...values, swiftIbanNo: e.target.value })
                  }
                />
                {errors.swiftIbanNo.error ? (
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    pt={0.5}
                  >
                    <CancelOutlined
                      sx={{
                        fontSize: "0.875em",
                        color: "red",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setErrors({
                          ...errors,
                          swiftIbanNo: { error: false, message: "" },
                        })
                      }
                    />
                    <Typography variant="body3" color="red" textAlign="left">
                      {errors.swiftIbanNo.message}
                    </Typography>
                  </Stack>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <Grid xs={1} />
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                  Routing Number
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledTextBox
                  fullWidth
                  value={values.routingNo}
                  onChange={(e) =>
                    setValues({ ...values, routingNo: e.target.value })
                  }
                />
                {errors.routingNo.error ? (
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    pt={0.5}
                  >
                    <CancelOutlined
                      sx={{
                        fontSize: "0.875em",
                        color: "red",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setErrors({
                          ...errors,
                          routingNo: { error: false, message: "" },
                        })
                      }
                    />
                    <Typography variant="body3" color="red" textAlign="left">
                      {errors.routingNo.message}
                    </Typography>
                  </Stack>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Grid>

          <br></br>
          <Grid container justifyContent="flex-start">
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                  Type
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledSelectTextBox
                  fullWidth
                  select
                  value={values.payAccountType}
                  onChange={(e) =>
                    setValues({ ...values, payAccountType: e.target.value })
                  }
                >
                  <MenuItem value="CHECKING">Checking</MenuItem>
                  <MenuItem value="SAVINGS">Savings</MenuItem>
                </StyledSelectTextBox>
              </Grid>
            </Grid>
            <Grid xs={1} />
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                  Bank Branch Code
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledTextBox
                  fullWidth
                  value={values.branchCode}
                  onChange={(e) =>
                    setValues({ ...values, branchCode: e.target.value })
                  }
                />
                {errors.branchCode.error ? (
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    pt={0.5}
                  >
                    <CancelOutlined
                      sx={{
                        fontSize: "0.875em",
                        color: "red",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setErrors({
                          ...errors,
                          branchCode: { error: false, message: "" },
                        })
                      }
                    />
                    <Typography variant="body3" color="red" textAlign="left">
                      {errors.branchCode.message}
                    </Typography>
                  </Stack>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Grid>
          <br></br>
          <Grid container justifyContent="flex-start">
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                  Bank Address
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledTextBox
                  fullWidth
                  value={values.bankAddress}
                  onChange={(e) =>
                    setValues({ ...values, bankAddress: e.target.value })
                  }
                />
                {errors.bankAddress.error ? (
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    pt={0.5}
                  >
                    <CancelOutlined
                      sx={{
                        fontSize: "0.875em",
                        color: "red",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setErrors({
                          ...errors,
                          bankAddress: { error: false, message: "" },
                        })
                      }
                    />
                    <Typography variant="body3" color="red" textAlign="left">
                      {errors.bankAddress.message}
                    </Typography>
                  </Stack>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <Grid xs={1} />
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                  Bank City
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledTextBox
                  fullWidth
                  value={values.bankCity}
                  onChange={(e) =>
                    setValues({ ...values, bankCity: e.target.value })
                  }
                />
                {errors.bankCity.error ? (
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    pt={0.5}
                  >
                    <CancelOutlined
                      sx={{
                        fontSize: "0.875em",
                        color: "red",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setErrors({
                          ...errors,
                          bankCity: { error: false, message: "" },
                        })
                      }
                    />
                    <Typography variant="body3" color="red" textAlign="left">
                      {errors.bankCity.message}
                    </Typography>
                  </Stack>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Grid>
          <br></br>
          <Grid container justifyContent="flex-start">
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                  Bank State
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledTextBox
                  fullWidth
                  value={values.bankState}
                  onChange={(e) =>
                    setValues({ ...values, bankState: e.target.value })
                  }
                />
                {errors.bankState.error ? (
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    pt={0.5}
                  >
                    <CancelOutlined
                      sx={{
                        fontSize: "0.875em",
                        color: "red",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setErrors({
                          ...errors,
                          bankState: { error: false, message: "" },
                        })
                      }
                    />
                    <Typography variant="body3" color="red" textAlign="left">
                      {errors.bankState.message}
                    </Typography>
                  </Stack>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <Grid xs={1} />
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                  Zip Code
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledTextBox
                  fullWidth
                  value={values.zipCode}
                  onChange={(e) =>
                    setValues({ ...values, zipCode: e.target.value })
                  }
                />
                {errors.zipCode.error ? (
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    pt={0.5}
                  >
                    <CancelOutlined
                      sx={{
                        fontSize: "0.875em",
                        color: "red",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setErrors({
                          ...errors,
                          zipCode: { error: false, message: "" },
                        })
                      }
                    />
                    <Typography variant="body3" color="red" textAlign="left">
                      {errors.zipCode.message}
                    </Typography>
                  </Stack>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Grid>
          <br></br>
          <Grid container justifyContent="flex-start">
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                  Country
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledSelectTextBox
                  fullWidth
                  select
                  value={values.country}
                  onChange={(e) =>
                    setValues({ ...values, country: e.target.value })
                  }
                >
                  <MenuItem value="United States">United States</MenuItem>
                  <MenuItem value="Canada">Canada</MenuItem>
                </StyledSelectTextBox>
              </Grid>
            </Grid>
            <Grid xs={1} />
          </Grid>
        </AccordionDetails>
      </Accordion>
      <br></br>
      <ContainedSearchButton
        variant="contained"
        onClick={(e) => handleSubmit(e)}
      >
        Save changes
      </ContainedSearchButton>

      <Snackbar
        open={alert.states}
        onClose={() => setAlert({ ...alert, states: false })}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{ top: "0 !important", right: "0 !important" }}
        autoHideDuration={6000}
      >
        <Box
          onClose={() => setAlert({ ...alert, states: false })}
          sx={{ width: "100vw", background: alert.color, p: 0.5 }}
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{
              width: "100%",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
              color: "#FFFFFF",
            }}
          >
            {alert.type ? (
              <>
                <CheckCircleOutlineOutlined fontSize="small" />
                <Typography variant="body2">{alert.message}</Typography>
              </>
            ) : (
              <>
                <CancelOutlined fontSize="small" />
                <Typography variant="body2">{alert.message}</Typography>
              </>
            )}
          </Stack>
        </Box>
      </Snackbar>
    </div>
  );
}
