import * as React from "react";
import { Typography, Grid, Stack } from "@mui/material";
// import Summary from "./Summary";
// import RecentTransfers from "./RecentTransfers";
// import DataTable from "./DataTable";
import TransactionTracker from "./TransactionTracker";

export default function Transactions() {
	return (
		<div>
			<Stack direction="row" justifyContent="space-between" alignItems="center">
				<Typography variant="h3">Transactions</Typography>
			</Stack>
			<br></br>
			{/* <Grid container spacing={2} sx={{ p: 2 }}>
				<Grid item xs={7} sx={{ pr: 2 }}>
					<Summary />
				</Grid>
				<Grid item xs={5} sx={{background: "#ffff",
					boxShadow: "0px 2px 8px rgba(3, 40, 120, 0.18)",
					borderRadius: "18px",}}>
					<RecentTransfers />
				</Grid>
			</Grid> */}
			{/* <DataTable /> */}
			<br></br>
			<Grid container spacing={2} sx={{ p: 2 }}>
				<Grid item xs={12}>
					<TransactionTracker />
				</Grid>
			</Grid>
		 
		</div>
	);
}
