import * as React from "react";
import { useState, useEffect } from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  MenuItem,
  Stack
} from "@mui/material";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { StyledSelectTextBox, StyledTextBox } from "../../../components/TextBox";
import { ContainedSearchButton } from "../../../components/Buttons";
import validator from "validator";
import CancelOutlined from "@mui/icons-material/CancelOutlined";


export default function BusinessInformation() {
  const [values, setValues] = useState({
    address: "",
    businessNature: "nodata",
    clientEncryptedId: "",
    contactNo: "",
    country: "United States",
    currency: "nodata",
    email: "",
    industry: "EDUCATION",
    name: "",
    registrationNo: "",
    remarks: "nodata",
    taxId: "nodata",
    timezone: "nodata",
    amazonId: "",
    avgSalesPerMonth: 0,
    city: "",
    courierServiceProvider: "AMAZON",
    lastMonthSales: 0,
    numMonthsInAmazon: 0,
    productCategoriesSold: "",
    secondaryEmail: "",
    state: "",
    storeName: "",
    storeUrl: "",
    zipCode: ""
  })


  const [errors, setErrors] = useState({
    name: {error: false, message: ''},
    email: {error: false, message: ''},
    address: {error: false, message: ''},
    contactNo: {error: false, message: ''},
    amazonId: {error: false, message: ''},
  })


  useEffect(() => {
    if(localStorage.getItem('businessInfo')){
      setValues(JSON.parse(localStorage.getItem('businessInfo')))
    }
}, [])


  const handleSubmit = (e) => {
    e.preventDefault()
    let error = false    

    const requiredFields = Object.keys(errors)

    for(let i = 0; i < requiredFields.length; i++){
      const field = requiredFields[i]
      if(values[field] === ""){
        let errorMessage;
        switch (field) {
          case field:
            errorMessage= 'This field is required';
            break;
          default:
            errorMessage= 'This field is required';
            break;
        }
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: { error: true, message: errorMessage },
        }));
        error = true;
      }else if (values[field] !== "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: { error: false, message: '' },
        }));
        error = false
      }
    }


    if(!validator.isEmail(values.email)){
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: { error: true, message: 'Enter a valid email' },
      }));
      error = true;
    }


    

    if(!error){
      localStorage.setItem('businessInfo', JSON.stringify(values))
      localStorage.setItem('newClentStep', 3)
      window.location = "/client-admin/client-information/new-client"
    }

  }



  return (
    <div>
      <Accordion expanded>
        <AccordionSummary
        
        sx={{backgroundColor: '#F1F5FC', border: '1px solid #D6D9E1',borderRadius: '8px 8px 0px 0px'}}
          // expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            {values.name === "" ? "Business Name" : values.name}
          </Typography>
         
        </AccordionSummary>
        <AccordionDetails>
        <br></br>
          <Grid container justifyContent="flex-start">
          <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                  Segment
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledSelectTextBox
                 fullWidth 
                 select
                 value={values.industry} 
                 onChange={(e) => setValues({...values, industry: e.target.value})}
                 >
                  <MenuItem value="ECOMMERCE">Ecommerce</MenuItem>
                  <MenuItem value="EDUCATION">Education</MenuItem>
                 </StyledSelectTextBox>
              </Grid>
            </Grid>
            <Grid xs={1} />
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                  Business Name
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledTextBox fullWidth value={values.name} onChange={(e) => setValues({...values, name: e.target.value})}/>
                {errors.name.error ? (
               <Stack direction="row" alignItems="center" spacing={1} pt={0.5}>
               <CancelOutlined sx={{fontSize: '0.875em', color: 'red', cursor: 'pointer'}} onClick={() => setErrors({...errors, name: {error: false, message: ''}})}/> 
               <Typography variant="body3" color="red" textAlign="left">{errors.name.message}</Typography>
               </Stack>
            ):''}
              </Grid>
            </Grid>
          </Grid>
          <br></br>
          <Grid container justifyContent="flex-start">
          <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                  Connected Marketplaces
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledTextBox fullWidth value="Amazon" disabled/>
              </Grid>
            </Grid>
            <Grid xs={1} />
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                  Amazon Id
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledTextBox fullWidth value={values.amazonId} onChange={(e) => setValues({...values, amazonId: e.target.value})} />
                {errors.amazonId.error ? (
               <Stack direction="row" alignItems="center" spacing={1} pt={0.5}>
               <CancelOutlined sx={{fontSize: '0.875em', color: 'red', cursor: 'pointer'}} onClick={() => setErrors({...errors, amazonId: {error: false, message: ''}})}/> 
               <Typography variant="body3" color="red" textAlign="left">{errors.amazonId.message}</Typography>
               </Stack>
            ):''}
              </Grid>
            </Grid>
          </Grid>
          <br></br>
          <Grid container justifyContent="flex-start">
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                  Address
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledTextBox fullWidth value={values.address} onChange={(e) => setValues({...values, address: e.target.value})} />
                {errors.address.error ? (
               <Stack direction="row" alignItems="center" spacing={1} pt={0.5}>
               <CancelOutlined sx={{fontSize: '0.875em', color: 'red', cursor: 'pointer'}} onClick={() => setErrors({...errors, address: {error: false, message: ''}})}/> 
               <Typography variant="body3" color="red" textAlign="left">{errors.address.message}</Typography>
               </Stack>
            ):''}
              </Grid>
            </Grid>
            <Grid xs={1} />
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                  Country
                </Typography>
              </Grid>
              <Grid xs={9}>
              <StyledSelectTextBox
                 fullWidth 
                 select
                 value={values.country} 
                 onChange={(e) => setValues({...values, country: e.target.value})}
                 >
                  <MenuItem value="United States">United States</MenuItem>
                  <MenuItem value="Canada">Canada</MenuItem>
                 </StyledSelectTextBox>
              </Grid>
            </Grid>
          </Grid>

          <br></br>
          <Grid container justifyContent="flex-start">
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                  Contact
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledTextBox fullWidth value={values.contactNo} onChange={(e) => setValues({...values, contactNo: e.target.value, registrationNo: e.target.value})}/>
                {errors.contactNo.error ? (
               <Stack direction="row" alignItems="center" spacing={1} pt={0.5}>
               <CancelOutlined sx={{fontSize: '0.875em', color: 'red', cursor: 'pointer'}} onClick={() => setErrors({...errors, contactNo: {error: false, message: ''}})}/> 
               <Typography variant="body3" color="red" textAlign="left">{errors.contactNo.message}</Typography>
               </Stack>
            ):''}
              </Grid>
            </Grid>
            <Grid xs={1} />
            <Grid xs={5} container direction="row" alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2" color="neutral.letter_Black">
                  Email
                </Typography>
              </Grid>
              <Grid xs={9}>
                <StyledTextBox fullWidth value={values.email} onChange={(e) => setValues({...values, email: e.target.value})} />
                {errors.email.error ? (
               <Stack direction="row" alignItems="center" spacing={1} pt={0.5}>
               <CancelOutlined sx={{fontSize: '0.875em', color: 'red', cursor: 'pointer'}} onClick={() => setErrors({...errors, email: {error: false, message: ''}})}/> 
               <Typography variant="body3" color="red" textAlign="left">{errors.email.message}</Typography>
               </Stack>
            ):''}
              </Grid>
            </Grid>
          </Grid>
          <br></br>
        </AccordionDetails>
      </Accordion>
      <br></br>
      <ContainedSearchButton variant="contained" onClick={(e) => handleSubmit(e)}>
        Continue
      </ContainedSearchButton>
    </div>
  );
}
