import React, { useEffect } from "react";
import { useState } from "react";
import {
  Stack,
  Typography,
  Grid,
  InputAdornment,
  ThemeProvider,
  IconButton,
  Snackbar,
  Box,
} from "@mui/material";
import { Theme } from "../../components/Theme";
import navLogo from "../../assets/navLogo.svg";
import users from "../../assets/users.svg";
import "./signinstyle.css";
import { ContainedButtonLogin } from "../../components/Buttons";
import Divider from "@mui/material/Divider";
import { StyledTextBoxLogin } from "../../components/TextBox";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
// import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
// import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from "@mui/material/Checkbox";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { StyledCheckboxes } from "../../components/Checkbox";
import { RequestAccessToken } from "../../services/auth.services";
import { DashboardCheck } from "../../services/dashboard.services";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useNavigate } from "react-router-dom";


export default function SignIn() {
  const [values, setValues] = useState({
    username: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [checkPassword, setCheckPassword] = useState(false);
  const [checkUserName, setCheckUserName] = useState(false);

  const [snackBarSuccess, setSnackBarSuccess] = useState(false);
  const [snackBarAlert, setSnackBarAlert] = useState(false);
  const [snackBarAlertMessage, setSnackBarAlertMessage] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    sessionStorage.clear();
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault();

    let error = false;

    if (values.username === "") {
      setCheckUserName(true);
      error = true;
      setSnackBarAlert(true);
    } else {
      setCheckUserName(false);
    }

    if (values.password === "") {
      setCheckPassword(true);
      error = true;
      setSnackBarAlert(true);
    } else {
      setCheckPassword(false);
    }

    if (!error) {
      try {
        RequestAccessToken(values).then((data) => {
          if (data.ok) {
            DashboardCheck().then((data) => {
              if(data === "Success") {
                setSnackBarSuccess(true);
                setTimeout(() => navigate("/dashboard"), 1000);
              } else {
                setSnackBarAlert(true);
                setSnackBarAlertMessage("The email or password you entered is incorrect.")
              }
            })
          }else{
            setSnackBarAlert(true);
            setSnackBarAlertMessage("Not authorized to access the portal")
          }
        });
      } catch (error) {
        setSnackBarAlert(true);
        setSnackBarAlertMessage("The email or password you entered is incorrect.")
        throw error
      }
     
    }
  };

  return (
    <ThemeProvider theme={Theme}>
      <div className="main">
        <Grid container alignItems="center" justifyContent="center">
          <Grid xs={5} direction="row" justifyContent="center">
            <Stack alignItems="center">
              <img src={navLogo} alt="Vepay" />
              <Typography
                variant="h1"
                sx={{ fontWeight: "bold", color: "#FFFFFF", marginTop: "3%" }}
              >
                Admin Login
              </Typography>
            </Stack>
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              border: "1px solid #FFFFFF",
              height: "525px",
              marginTop: "6%",
            }}
          />
          <Grid xs={5} direction="row" justifyContent="flex-end">
            <Stack alignItems="center">
              <img src={users} alt="Vepay" />

              <Stack spacing={1} sx={{ marginTop: "5%" }}>
                <Typography variant="body1" sx={{ color: "#FFFFFF" }}>
                  Username or Email
                </Typography>
                <StyledTextBoxLogin
                  placeholder="Username or Email"
                  error={checkUserName}
                  value={values.username}
                  onChange={(e) =>
                    setValues({ ...values, username: e.target.value })
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Person2OutlinedIcon sx={{ color: "#868B98" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
              <Stack spacing={1} sx={{ marginTop: "2%" }}>
                <Typography variant="body1" sx={{ color: "#FFFFFF" }}>
                  Password
                </Typography>
                <StyledTextBoxLogin
                  placeholder="Password"
                  error={checkPassword}
                  type={showPassword ? "text" : "password"}
                  value={values.password}
                  onChange={(e) =>
                    setValues({ ...values, password: e.target.value })
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOutlinedIcon sx={{ color: "#868B98" }} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ paddingTop: "2%" }}
                >
                  <Stack direction="row" spacing={1}>
                    <StyledCheckboxes
                      control={<Checkbox label="Remember me" variant="body2" />}
                    ></StyledCheckboxes>
                    <Typography
                      variant="body2"
                      sx={{ color: "#FFFFFF" }}
                      textAlign="left"
                    >
                      Remember me
                    </Typography>
                  </Stack>
                  <a
                    href="forget-password"
                    style={{
                      textDecoration: "none",
                      color: "#FFFFFF",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    Forget password
                  </a>
                </Stack>
              </Stack>

              <Stack
                spacing={1}
                alignItems="flex-start"
                sx={{ marginTop: "4%" }}
              >
                <ContainedButtonLogin
                  sx={{ width: "32.75em" }}
                  fullWidth
                  variant="contained"
                  onClick={(e) => handleSubmit(e)}
                >
                  Log In
                </ContainedButtonLogin>
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        <Snackbar
          open={snackBarSuccess}
          onClose={() => setSnackBarSuccess(false)}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          sx={{ top: "0 !important", left: "0 !important" }}
          autoHideDuration={6000}
        >
          <Box
            onClose={() => setSnackBarSuccess(false)}
            sx={{ width: "100vw", background: "#00AD47", p:0.5}}
            
          >
            <Stack direction="row" spacing={2} sx={{width: '100%', textAlign: 'center', alignItems: 'center', justifyContent:'center', color: '#FFFFFF' }} >
              <CheckCircleOutlineOutlinedIcon fontSize="small"/> <Typography variant="body2" >Authentification succsessful!</Typography>
            </Stack>
            
          </Box>
        </Snackbar>

        <Snackbar
          open={snackBarAlert}
          onClose={() => setSnackBarAlert(false)}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          sx={{ top: "0 !important", left: "0 !important" }}
          autoHideDuration={6000}
        >
          <Box
            onClose={() => setSnackBarAlert(false)}
            sx={{ width: "100vw", background: "#F33535", p:0.5}}
            
          >
            <Stack direction="row" spacing={2} sx={{width: '100%', textAlign: 'center', alignItems: 'center', justifyContent:'center', color: '#FFFFFF' }} >
              <CancelOutlinedIcon fontSize="small"/> <Typography variant="body2" >{snackBarAlertMessage}</Typography>
            </Stack>
            
          </Box>
        </Snackbar>
      </div>
    </ThemeProvider>
  );
}
