import React, { useEffect, useState } from "react";
import {
	Box,
	Chip,
	Grid,
	CircularProgress,
	Popover,
	Stack,
	Typography,
	TextField,
	MenuItem
} from "@mui/material";
import LedgersGrouped from "./LedgersGrouped";
import LedgerBreakdown from "./LedgerBreakdown";
import { LoadingBox } from "../../components/Box";
import { GetAccountByName } from "../../services/dashboard.services";
import { GetBusinessList } from "../../services/business.services";
import { FilterAlt } from "@mui/icons-material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { LightBlueContainedButton, TextButton } from "../../components/Buttons";
import CloseIcon from "@mui/icons-material/Close";
import ExcelExport from "../../components/ExcelExport";
import { extractAmazonId } from "../../utils/utilityFunctions";
import { StyledSelectTextBox } from "../../components/TextBox"

export default function Ledgers() {

	let reportStartDate = moment().subtract(365, "days").toDate();
	let reportEndDate = moment().toDate();

	const [selectedLedger, setSelectedLedger] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [ledgerDataBreakdown, setLedgerDataBreakdown] = useState(null);
	const [businessList, setBusinessList] = useState(null);
	const [filter, setFilter] = useState(null); //state of the filter
	const [startDate, setStartDate] = useState(reportStartDate);
	const [endDate, setEndDate] = useState(reportEndDate);
	const [startDateError, setStartDateError] = useState(false);
	const [endDateError, setEndDateError] = useState(false);
	const [value, setValue] = useState([startDate, endDate]); //state of date picker in filter
	const [businessId, setBusinessId] = useState(0);

	useEffect(() => {
		(async () => {
			setIsLoading(false);
			try {
				if (selectedLedger) {					
					await GetAccountByName(selectedLedger.name, businessId).then((data) => {
						setIsLoading(true);
						setLedgerDataBreakdown(data);
					});
				}
				await GetBusinessList().then((data) => {
					setIsLoading(true);
					setBusinessList(data);
				});
				setIsLoading(false);
			} catch (error) {
				throw error;
			}
		})();
	}, [selectedLedger, businessId]);

	if ((selectedLedger && !ledgerDataBreakdown) || !businessList)
		return (
			<LoadingBox>
				<CircularProgress />
			</LoadingBox>
		);

	const handleFilter = () => {
		setValue([startDate, endDate]);
		handleCloseMP();
	};

	const handlerFilterReset = () => {
		setStartDate(moment().subtract(7, "days").toDate());
		setEndDate(new Date());
		handleFilter();
	};

	const filteredTransactions = ledgerDataBreakdown?.filter(transaction => {
		const transactionDate = new Date(transaction.createdDate);
		const transactionDateOnly = new Date(transactionDate.getFullYear(), transactionDate.getMonth(), transactionDate.getDate());
	
		const startDate = new Date(value[0].getFullYear(), value[0].getMonth(), value[0].getDate());
		const endDate = new Date(value[1].getFullYear(), value[1].getMonth(), value[1].getDate());
	
		return transactionDateOnly >= startDate && transactionDateOnly <= endDate;
	});

	const ledgers = [
		{ name: 'INVENTORY', description: 'Inventory' },
		{ name: 'SELLER_WALLET', description: 'Seller Wallet' },
		{ name: 'INCENTIVE_FEE_PAYABLE', description: 'Incentive Fee' },
		{ name: 'AGENCY_FEE_PAYABLE', description: 'Agency Fee' },
		{ name: 'VEPAY_REVENUE_REALIZED', description: 'Rev. Realized' },
		{ name: 'VEPAY_REVENUE_RECOGNIZED', description: 'Rev. Recog.' },
		{ name: 'PURCHASE', description: 'Purchase' },
		{ name: 'VEPAY_REVENUE', description: 'VePay Revenue' },
		{ name: 'MARKETPLACE', description: 'Markeplace' },
		{ name: 'MARKETPLACE_RESERVE_RELEASE_CONTROL', description: 'Markeplace Reserve Release Control' },
		{ name: 'MARKETPLACE_RECEIVABLE', description: 'Markeplace Receivable' },
		{ name: 'VEPAY_PROFIT', description: 'Vepay Profit' },
		{ name: 'VEPAY_CLIENT_WITHDRAWAL', description: 'Vepay Client Withdrawal' },
		{ name: 'VEPAY_CLIENT_WITHDRAWAL_CONTROL', description: 'Vepay Client Withdrawal Control' },
		{ name: 'VEPAY_CAPITAL', description: 'Vepay Capital' },
		{ name: 'VEPAY_BANK_ACCOUNT', description: 'Vepay Bank Account' },
		{ name: 'VEPAY_BANK_ACCOUNT_CONTROL', description: 'Vepay Bank Account Control' },
		{ name: 'EXPECTED_MARKETPLACE', description: 'Expected Marketplace' },
		{ name: 'INVENTORY_CONTROL', description: 'Inventory Control' },
		{ name: 'EXPECTED_REVENUE_RECOGNIZED_CONTROL', description: 'Expected Revenue Recognized Control' },
		{ name: 'EXPECTED_INCENTIVE_FEE_PAYABLE_CONTROL', description: 'Expected Incentive Fee Payable Control' },
		{ name: 'EXPECTED_AGENCY_FEE_PAYABLE_CONTROL', description: 'Expected Agency Fee Payable Control' },
		{ name: 'CAPITAL_RECOVERY_CONTROL', description: 'Capital Recovery Control' },
		{ name: 'INCENTIVE_ADJUSTMENT_CONTROL', description: 'Incentive Adjustment Control' },
		{ name: 'RETURN_REVENUE_RECEIVABLE_CONTROL', description: 'Return Revenue Receivable Control' },
		{ name: 'REMAINING_RECOVERY', description: 'Remaining Recovery' },
		{ name: 'PRE_FACTORING_INVOICES', description: 'Pre Factoring Invoices' }
	];

	const openMP = Boolean(filter);
	const idMP = openMP ? "simple-popover" : undefined;

	const handleClickMP = (event) => {
		setFilter(event.currentTarget);
	};

	const handleCloseMP = () => {
		setFilter(null);
	};

	const exportData = filteredTransactions?.map((row) => ({
		"Date": moment(row.createdDate).format("MMM D, y"),
		"Marketplace": 'Amazon',
		"Region": '',
		"Store Name": '',
		"Order ID": extractAmazonId(row.meta),
		"Description": row.description,
		"Debit ($)": row.debit,
		"Credit ($)": row.credit,
		"Balance ($)": row.note,
	}));

	return (
		<div>
			<Box
				sx={{
					background: "#FFFFFF",
					boxShadow: "0px 2px 8px rgba(3, 40, 120, 0.18)",
					borderRadius: "18px",
					p: 5,
				}}
			>
				<Stack direction="row" spacing={2} alignItems="center" sx={{mb: 2}}>
					<Typography variant="body1">Select Business</Typography>
					<StyledSelectTextBox 
						value={businessId}
						onChange={(e) => setBusinessId(e.target.value)}
						displayEmpty
					>
						    <MenuItem value="0" disabled>Select an option</MenuItem>
						{businessList?.filter(value => value.businessStatus === 'APPROVED').map((row) => (
							<MenuItem value={row.id}>{row.name}</MenuItem>
						))}
					</StyledSelectTextBox>
				</Stack>
				
				
				<Grid container spacing={1}>
					{ledgers.map((row, index) => (
						<Grid item xs="auto" key={index}>
							<Chip
								label={row.description}
								variant={selectedLedger?.name === row.name ? '' : 'outlined'}
								color={selectedLedger?.name === row.name ? 'primary' : 'default'}
								onClick={() => {
									setSelectedLedger(row)
								}}
								onDelete={selectedLedger?.name === row.name ? () => setSelectedLedger(null) : undefined}
							/>
						</Grid>
					))}
				</Grid>
				<br></br>
				{selectedLedger ? (
					<>
						{isLoading ?
							<LoadingBox>
								<CircularProgress />
							</LoadingBox>
							:
							<>
								<Stack
									direction="row"
									justifyContent="space-between"
									alignItems="center"
								>
									<Stack direction="row" alignItems="center" spacing={2}>
										<Typography variant="h4">'{selectedLedger.description}' Ledger Breakdown</Typography>
										<Typography variant="body2">
											Time period {moment(value[0]).format("MMM D, y")} -&nbsp;
											{moment(value[1]).format("MMM D, y")}
										</Typography>
									</Stack>
									<Stack direction="row" alignItems="center" spacing={2}>
										<TextButton variant="text" onClick={handleClickMP}>
											<FilterAlt />
										</TextButton>
										<ExcelExport
											filename={
												`${selectedLedger.description} Ledger Breakdown from ${moment(value[0]).format("MMM D, y")} to ${moment(value[1]).format("MMM D, y")}`
											}
											excelData={exportData}
										/>
									</Stack>
								</Stack>
								<Popover
									id={idMP}
									open={openMP}
									anchorEl={filter}
									onClose={handleCloseMP}
									PaperProps={{
										style: {
											padding: "1% 2% 2% 2%",
											width: "25%",
											borderRadius: "10px",
										},
									}}
									anchorOrigin={{
										vertical: "top",
										horizontal: "right",
									}}
									transformOrigin={{
										vertical: "top",
										horizontal: "right",
									}}
								>
									<Stack
										direction="row"
										justifyContent="space-between"
										spacing={2}
										alignItems="center"
									>
										<Typography sx={{ p: 2 }} variant="h5">
											<b>Filter Report</b>
										</Typography>
										<CloseIcon onClick={handleCloseMP} />
									</Stack>
									<br></br>
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<div>
											<Stack sx={{ width: "100%" }} spacing={2}>
												<DatePicker
													openTo="day"
													views={["year", "month", "day"]}
													label="Start"
													value={startDate}
													maxDate={endDate}
													onChange={(newValue) => {
														setStartDate(newValue);
													}}
													renderInput={(params) => (
														<>
															{setStartDateError(params.error)}
															<TextField {...params} helperText={null} />
														</>
													)}
												/>
												<DatePicker
													openTo="day"
													views={["year", "month", "day"]}
													label="End"
													value={endDate}
													minDate={startDate}
													onChange={(newValue) => {
														setEndDate(newValue);
													}}
													renderInput={(params) => (
														<>
															{setEndDateError(params.error)}
															<TextField {...params} helperText={null} />
														</>
													)}
												/>
											</Stack>
										</div>
									</LocalizationProvider>
									<br></br>
									<br></br>
									<Stack direction="row" spacing={2}>
										{startDateError === false && endDateError === false ? (
											<LightBlueContainedButton
												fullWidth
												variant="outlined"

												onClick={handleFilter}
												size="large"
											>
												Filter
											</LightBlueContainedButton>
										) : (
											<LightBlueContainedButton fullWidth variant="outlined" disabled>
												Apply
											</LightBlueContainedButton>
										)}
										<LightBlueContainedButton
											fullWidth
											variant="contained"
											onClick={handlerFilterReset}
											size="large"
										>
											Reset
										</LightBlueContainedButton>
									</Stack>
								</Popover>

								<LedgerBreakdown ledgerDataBreakdown={filteredTransactions} />
							</>
						}
					</>) : (
					<LedgersGrouped />
				)}
			</Box>
			<br></br>
		</div>
	);
}
