import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

import "@fontsource/open-sans";

export const ContainedButton = styled(Button)(({ theme }) => ({
  boxShadow: ["none"],
  backgroundColor: theme.palette.primary.Primary_Blue,
  textTransform: "capitalize",
  fontWeight: "bold",
  color: theme.palette.neutral.Pure_White_Bg,
  "&:hover": {
    backgroundColor: theme.palette.primary.Primary_Blue,
    boxShadow: ["none"],
  },
  padding: "10px 30px",
  borderRadius: '12px',
}));

export const OutlinedButton = styled(Button)(({ theme }) => ({
  boxShadow: ["none"],
  backgroundColor: theme.palette.neutral.White_Bg_Dark,
  textTransform: "capitalize",
  fontWeight: "bold",
  color: theme.palette.primary.Blue_Dark,
  "&:hover": {
    backgroundColor: theme.palette.neutral.White_Bg_Dark,
    boxShadow: ["none"],
  },
  padding: "10px 30px",
  borderRadius: '12px',
}));

export const TextButton = styled(Button)(({ theme }) => ({
  boxShadow: ["none"],
  backgroundColor: theme.palette.primary.Pure_White_Bg,
  textTransform: "capitalize",
  fontFamily: "open Sans",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "1em", //14px
  lineHeight: "1.125em",
  color: theme.palette.primary.Blue_Dark,
  "&:hover": {
    backgroundColor: theme.palette.primary.Blue_Bg_Lite,
    boxShadow: ["none"],
  },
  padding: "8px 12px",
}));

export const RoundEdgeContainedButton = styled(Button)(({ theme }) => ({
  boxShadow: ["none"],
  backgroundColor: theme.palette.primary.Pure_White_Bg,
  textTransform: "capitalize",
  fontFamily: "open Sans",
  fontStyle: "normal",
  fontWeight: 700,
  color: theme.palette.neutral.Pure_White_Bg,
  "&:hover": {
    backgroundColor: theme.palette.primary.Pure_White_Bg,
    boxShadow: ["none"],
  },
  padding: "10px 23px",
  borderRadius: "24px",
}));

export const ContainedSearchButton = styled(Button)(({ theme }) => ({
  boxShadow: ["none"],
  backgroundColor: theme.palette.success.Success_Dk,
  textTransform: "capitalize",
  fontWeight: "bold",
  color: theme.palette.neutral.Pure_White_Bg,
  "&:hover": {
    backgroundColor: theme.palette.success.Success_Dk,
    boxShadow: ["none"],
  },
  padding: "10px 30px",
  borderRadius: "12px",
}));

export const ContainedErrorButton = styled(Button)(({ theme }) => ({
  boxShadow: ["none"],
  backgroundColor: theme.palette.danger.Danger_Dk,
  textTransform: "capitalize",
  fontWeight: "bold",
  color: theme.palette.neutral.Pure_White_Bg,
  "&:hover": {
    backgroundColor: theme.palette.danger.Danger_Dk,
    boxShadow: ["none"],
  },
  padding: "10px 30px",
  borderRadius: "12px",
}));

export const ContainedButtonLogin = styled(Button)(({ theme }) => ({
  boxShadow: ["none"],
  height: "3em",
  backgroundColor: theme.palette.primary.Blue_Dark,
  textTransform: "capitalize",
  fontWeight: "bold",
  color: theme.palette.neutral.Pure_White_Bg,
  "&:hover": {
    backgroundColor: theme.palette.primary.Blue_Dark,
    boxShadow: ["none"],
  },
  padding: "10px 30px",
}));


export const TextButtonModal = styled(Button)(({ theme }) => ({
  boxShadow: ["none"],
  backgroundColor: theme.palette.primary.Pure_White_Bg,
  textTransform: "capitalize",
  fontFamily: "open Sans",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "1em", //14px
  lineHeight: "1.125em",
  color: theme.palette.primary.Blue_Dark,
  "&:hover": {
    backgroundColor: theme.palette.primary.Blue_Bg_Lite,
    boxShadow: ["none"],
  },
  padding: "8px 12px",
  border: "1px solid #093BA7",
  borderRadius: "12px",
}));


export const ContainedAshButton = styled(Button)(({ theme }) => ({
  boxShadow: ["none"],
  backgroundColor: theme.palette.neutral.Gray_Lite,
  textTransform: "capitalize",
  fontWeight: "bold",
  color: theme.palette.neutral.Pure_White_Bg,
  "&:hover": {
    backgroundColor: theme.palette.neutral.Gray_Lite,
    boxShadow: ["none"],
  },
  padding: "10px 30px",
  borderRadius: "12px",
}));

export const LightBlueContainedButton = styled(Button)(({ theme }) => ({
  boxShadow: ["none"],
  backgroundColor: theme.palette.primary.Blue_Bg_Lite,
  textTransform: "capitalize",
  fontWeight: "bold",
  color: theme.palette.primary.Primary_Blue,
  "&:hover": {
    backgroundColor: theme.palette.primary.Blue_Bg_Dark,
    boxShadow: ["none"],
  },
  borderRadius: '2px',
}));
