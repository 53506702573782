import * as React from "react";
// import { useState } from "react";
import {
  Typography,
  Box,
	Stack,
//   InputAdornment,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Tooltip,
//   TablePagination,
} from "@mui/material";
// import { StyledTextBox } from "../../components/TextBox";
// import SearchIcon from "@mui/icons-material/Search";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
// import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// import Moment from "moment";
import {
  StyledTableContainer,
  StyledTableCell,
} from "../../components/Table";
// import Collapse from "@mui/material/Collapse";
// import IconButton from "@mui/material/IconButton";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// import { GetTransactions } from "../../services/transactions.services";
// import { formatCurrency } from "../../utils/utilityFunctions";
// import CircularProgress from "@mui/material/CircularProgress";
// import { LoadingBox } from "../../components/Box";
// import moment from "moment";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function TableData(props) {
//   const [data, setData] = useState(props.data);
//   const [open, setOpen] = useState(false);

//   useEffect(() => {
//     setData(props.data);
//     // eslint-disable-next-line
//   }, []);

  return (
	<>
	<TableRow>
		<TableCell align="left" variant="body2">
		</TableCell>
		<TableCell align="left" variant="body2">
		</TableCell>
		<TableCell align="center" variant="body2" sx={{borderLeft: '1px solid #c8d2d8'}}>
			<Tooltip title="Purchase" placement="top">
            	<Typography variant="body2">1</Typography>
          	</Tooltip>
		</TableCell>
		<TableCell align="center" variant="body2">
			<Tooltip title="Seller Wallet" placement="top">
            	<Typography variant="body2">3</Typography>
          	</Tooltip>
		</TableCell>
		<TableCell align="center" variant="body2">
			<Tooltip title="VePay Client Withdrawal" placement="top">
            	<Typography variant="body2">11</Typography>
          	</Tooltip>
		</TableCell>
		<TableCell align="center" variant="body2">
			<Tooltip title="VePay Capital" placement="top">
            	<Typography variant="body2">12</Typography>
          	</Tooltip>
		</TableCell>
		<TableCell align="center" variant="body2">
			<Tooltip title="Expected Marketplace Receivable Control" placement="top">
            	<Typography variant="body2">15</Typography>
          	</Tooltip>
		</TableCell>
		<TableCell align="center" variant="body2">
		<Tooltip title="Capital Recovery Control" placement="top">
            	<Typography variant="body2">19</Typography>
          	</Tooltip>
		</TableCell>
		<TableCell align="center" variant="body2">
		</TableCell>
		<TableCell align="center" variant="body2" sx={{borderLeft: '1px solid #c8d2d8'}}>
			<Tooltip title="Purchase" placement="top">
            	<Typography variant="body2">1</Typography>
          	</Tooltip>
		</TableCell>
		<TableCell align="center" variant="body2">
			<Tooltip title="Inventory" placement="top">
            	<Typography variant="body2">2</Typography>
          	</Tooltip>
		</TableCell>
		<TableCell align="left" variant="body2">
		</TableCell>
		<TableCell align="center" variant="body2" sx={{borderLeft: '1px solid #c8d2d8'}}>
			<Tooltip title="Purchase" placement="top">
            	<Typography variant="body2">1</Typography>
          	</Tooltip>
		</TableCell>
		<TableCell align="center" variant="body2">
			<Tooltip title="Incentive Fee Payable" placement="top">
            	<Typography variant="body2">4</Typography>
          	</Tooltip>
		</TableCell>
		<TableCell align="center" variant="body2">
			<Tooltip title="Agency Fee Payable" placement="top">
            	<Typography variant="body2">5</Typography>
          	</Tooltip>
		</TableCell>
		<TableCell align="center" variant="body2">
			<Tooltip title="VePay Revenue (Recognized)" placement="top">
            	<Typography variant="body2">6</Typography>
          	</Tooltip>
		</TableCell>
		<TableCell align="center" variant="body2">
			<Tooltip title="Amazon" placement="top">
            	<Typography variant="body2">8</Typography>
          	</Tooltip>
		</TableCell>
		<TableCell align="center" variant="body2">
			<Tooltip title="Expected Marketplace Receivable Control" placement="top">
            	<Typography variant="body2">15</Typography>
          	</Tooltip>
		</TableCell>
		<TableCell align="center" variant="body2">
			<Tooltip title="Expected Revenue Control" placement="top">
            	<Typography variant="body2">16</Typography>
          	</Tooltip>
		</TableCell>
		<TableCell align="center" variant="body2">
			<Tooltip title="Expected Incentive Fee Payable Control" placement="top">
            	<Typography variant="body2">17</Typography>
          	</Tooltip>
		</TableCell>
		<TableCell align="center" variant="body2">
			<Tooltip title="Expected Agency Fee Payable Control" placement="top">
            	<Typography variant="body2">18</Typography>
          	</Tooltip>
		</TableCell>
		<TableCell align="center" variant="body2">
		</TableCell>
		<TableCell align="center" variant="body2" sx={{borderLeft: '1px solid #c8d2d8'}}>
			
		</TableCell>
		<TableCell align="center" variant="body2" sx={{borderLeft: '1px solid #c8d2d8'}}>
			<Tooltip title="Inventory" placement="top">
            	<Typography variant="body2">2</Typography>
          	</Tooltip>
		</TableCell>
		<TableCell align="center" variant="body2">
			<Tooltip title="Incentive Fee Payable" placement="top">
            	<Typography variant="body2">4</Typography>
          	</Tooltip>
		</TableCell>
		<TableCell align="center" variant="body2">
			<Tooltip title="VePay Revenue (Recognized)" placement="top">
            	<Typography variant="body2">6</Typography>
          	</Tooltip>
		</TableCell>
		<TableCell align="center" variant="body2">
			<Tooltip title="Amazon" placement="top">
            	<Typography variant="body2">8</Typography>
          	</Tooltip>
		</TableCell>
		<TableCell align="center" variant="body2">
			<Tooltip title="Incentive Adjustment Control" placement="top">
            	<Typography variant="body2">20</Typography>
          	</Tooltip>
		</TableCell>
		<TableCell align="center" variant="body2">
		</TableCell>
	  </TableRow>
	  <TableRow>
		{/* <TableCell align="left" variant="body2">
		  {moment(data.requestDate).format("MMM D, y")}
		  <IconButton
			aria-label="expand row"
			size="small"
			onClick={() => setOpen(!open)}
		  >
			{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
		  </IconButton>
		</TableCell> */}

		<TableCell align="left" variant="body2">
			1/4/2024
		</TableCell>
		<TableCell align="left" variant="body2">
			112-9156531-7996215
		</TableCell>
		<TableCell align="left" variant="body2" sx={{borderLeft: '1px solid #c8d2d8'}}>
			<CheckCircleIcon color="success"/>
		</TableCell>
		<TableCell align="left" variant="body2">
			<CheckCircleIcon color="success"/>
		</TableCell>
		<TableCell align="left" variant="body2">
			<CheckCircleIcon color="success"/>
		</TableCell>
		<TableCell align="left" variant="body2">
			<CheckCircleIcon color="success"/>
		</TableCell>
		<TableCell align="left" variant="body2">
			<CheckCircleIcon color="success"/>
		</TableCell>
		<TableCell align="left" variant="body2">
			<CheckCircleIcon color="success"/>
		</TableCell>
		<TableCell align="left" variant="body2">
			<CheckCircleIcon color="success" sx={{ fontSize: 40 }}/>
		</TableCell>
		<TableCell align="left" variant="body2" sx={{borderLeft: '1px solid #c8d2d8'}}>
			<CheckCircleIcon color="success"/>
		</TableCell>
		<TableCell align="left" variant="body2">
			<CheckCircleIcon color="success"/>
		</TableCell>
		<TableCell align="left" variant="body2">
			<CheckCircleIcon color="success" sx={{ fontSize: 40 }}/>
		</TableCell>
		<TableCell align="left" variant="body2" sx={{borderLeft: '1px solid #c8d2d8'}}>
			<CheckCircleIcon color="success"/>
		</TableCell>
		<TableCell align="left" variant="body2">
			<CheckCircleIcon color="success"/>
		</TableCell>
		<TableCell align="left" variant="body2">
			<CheckCircleIcon color="success"/>
		</TableCell>
		<TableCell align="left" variant="body2">
			<CheckCircleIcon color="success"/>
		</TableCell>
		<TableCell align="left" variant="body2">
			<CheckCircleIcon color="success"/>
		</TableCell>
		<TableCell align="left" variant="body2">
			<CheckCircleIcon color="success"/>
		</TableCell>
		<TableCell align="left" variant="body2">
			<CheckCircleIcon color="success"/>
		</TableCell>
		<TableCell align="left" variant="body2">
			<CheckCircleIcon color="success"/>
		</TableCell>
		<TableCell align="left" variant="body2">
			<CheckCircleIcon color="success"/>
		</TableCell>
		<TableCell align="left" variant="body2">
			<CheckCircleIcon color="success" sx={{ fontSize: 40 }}/>
		</TableCell>
		<TableCell align="center" variant="body2" sx={{borderLeft: '1px solid #c8d2d8'}}>
			Received
		</TableCell>
		<TableCell align="left" variant="body2" sx={{borderLeft: '1px solid #c8d2d8'}}>
			<CheckCircleIcon sx={{ color: 'orange' }}/>
		</TableCell>
		<TableCell align="left" variant="body2">
			<CheckCircleIcon sx={{ color: 'orange' }}/>
		</TableCell>
		<TableCell align="left" variant="body2">
			<CheckCircleIcon sx={{ color: 'orange' }}/>
		</TableCell>
		<TableCell align="left" variant="body2">
			<CheckCircleIcon sx={{ color: 'orange' }}/>
		</TableCell>
		<TableCell align="left" variant="body2">
			<CheckCircleIcon sx={{ color: 'orange' }}/>
		</TableCell>
		<TableCell align="left" variant="body2">
			<CheckCircleIcon sx={{ color: 'orange', fontSize: 40 }}/>
		</TableCell>
	  </TableRow>
	</>
  );
}

export default function TransactionTracker() {
//   const [startDate, setStartDate] = useState(
// 	Moment().subtract(7, "days").toDate()
//   );
//   const [endDate, setEndDate] = useState(Moment().toDate());

//   const [transactions, setTransactions] = useState(null);
// //   const [isLoading, setIsLoading] = useState(false);
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(10);

//   useEffect(() => {
//     (async () => {
//       setIsLoading(false);
//       try {
//         await GetTransactions().then((data) => {
//           setIsLoading(true);
//           setTransactions(data);
//         });
//         setIsLoading(false);
//       } catch (error) {
//         throw error;
//       }
//     })();
//   }, []);

//   if (!transactions)
//     return (
//       <LoadingBox>
//         <CircularProgress />
//       </LoadingBox>
//     );

//   const handleChangePage = (event, newPage) => {
// 	setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
// 	setRowsPerPage(+event.target.value);
// 	setPage(0);
//   };

  return (
	<div>
	  <Box
		sx={{
		  background: "#FFFFFF",
		  boxShadow: "0px 2px 8px rgba(3, 40, 120, 0.18)",
		  borderRadius: "18px",
		  p: 5,
		}}
	  >
		<Stack sx={{ mb: 1 }}>
		  <Typography variant="h4" color="primary.Primary_Blue">
			Transaction Tracker
		  </Typography>
		</Stack>
		{/* <Stack spacing={2} direction="row">
		  <LocalizationProvider dateAdapter={AdapterMoment}>
			<Stack spacing={2}>
			  <Typography variant="body2">Start Date</Typography>
			  <DesktopDatePicker
				inputFormat="MM/DD/YYYY"
				value={startDate}
				onChange={(newValue) => {
				  setStartDate(newValue);
				}}
				renderInput={(params) => (
				  <StyledTextBox {...params} size="small" />
				)}
			  />
			</Stack>
			<Stack spacing={2}>
			  <Typography variant="body2">End Date</Typography>
			  <DesktopDatePicker
				inputFormat="MM/DD/YYYY"
				value={endDate}
				onChange={(newValue) => {
				  setEndDate(newValue);
				}}
				renderInput={(params) => (
				  <StyledTextBox {...params} size="small" />
				)}
			  />
			</Stack>
		  </LocalizationProvider>

		  <Stack spacing={2}>
			<Typography variant="body2">Search Record</Typography>
			<StyledTextBox
			  size="small"
			  placeholder="Reference ID"
			  endAdornment={
				<InputAdornment position="start">
				  <SearchIcon />
				</InputAdornment>
			  }
			/>
		  </Stack>
		</Stack> */}

		<br></br>

		<StyledTableContainer>
		  <Table sx={{ minWidth: 650 }} aria-label="simple table">
			<TableHead>
			  <TableRow sx={{ backgroundColor: "#F6F6F6" }}>
				<StyledTableCell align="left">Date</StyledTableCell>
				<StyledTableCell align="left">Order ID  </StyledTableCell>
				<StyledTableCell align="center" colSpan={7} sx={{borderLeft: '1px solid #c8d2d8'}}>Order Received</StyledTableCell>
				<StyledTableCell align="center" colSpan={3} sx={{borderLeft: '1px solid #c8d2d8'}}>
				Shipment Begin		
				</StyledTableCell>
				<StyledTableCell align="center" colSpan={10} sx={{borderLeft: '1px solid #c8d2d8'}}>
				Item Delivered									
				</StyledTableCell>
				<StyledTableCell align="center" sx={{borderLeft: '1px solid #c8d2d8'}}>
				Payment Status
				</StyledTableCell>
				<StyledTableCell align="center" colSpan={6} sx={{borderLeft: '1px solid #c8d2d8'}}>
				Refund Initiated
				</StyledTableCell>
			  </TableRow>
			</TableHead>
			<TableBody>
			  {/* {isLoading ? (
				<LoadingBox>
				  <CircularProgress />
				</LoadingBox>
			  ) : (
				""
			  )} */}
			  {/* {transactions
				.sort(
				  (a, b) =>
					parseFloat(b.requestDate) - parseFloat(a.requestDate)
				)
				.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
				.map((data, index) => ( */}
				<React.Fragment
					//key={index}
				>
					<TableData 
						// key={index} 
						// data={data} 
						// index={index} 
					/>
				  </React.Fragment>
				{/* ))} */}
			</TableBody>
		  </Table>
		</StyledTableContainer>
		{/* <TablePagination
		  rowsPerPageOptions={[10, 25, 100]}
		  component="div"
		  count={transactions.length}
		  rowsPerPage={rowsPerPage}
		  page={page}
		  onPageChange={handleChangePage}
		  onRowsPerPageChange={handleChangeRowsPerPage}
		/> */}
	  </Box>
	</div>
  );
}
