import React from 'react'
import XLSX from "sheetjs-style";
import { saveAs } from 'file-saver';
import { TextButton } from './Buttons';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';

export default function ExcelExport({ filename, excelData }) {

	const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";

	function handleDownloadExcel() {

		const ws = XLSX.utils.json_to_sheet(excelData);
		const excelBuffer = XLSX.write(
			{
				Sheets: { data: ws },
				SheetNames: ["data"],
			},
			{ bookType: "xlsx", type: "array" }
		);
		const exportData = new Blob([excelBuffer], { type: fileType });
		saveAs(exportData, filename + fileExtension);
	}

	return (
		<div>
			<TextButton variant="text" onClick={handleDownloadExcel}>
				<LocalPrintshopOutlinedIcon />
			</TextButton>
		</div>
	)
}
