import React from "react";
import {
	TableCell,
	Table,
	TableHead,
	TableRow,
	TableBody,
	// TablePagination,
	// Typography,
} from "@mui/material";
import { StyledTableCell, StyledTableContainer } from "../../components/Table";
// import { extractAmazonId, formatCurrency } from "../../utils/utilityFunctions";
// import moment from "moment";

export default function MasterTable() {
	// const [page, setPage] = useState(0);
	// const [rowsPerPage, setRowsPerPage] = useState(10);

	// const handleChangePage = (event, newPage) => {
	// 	setPage(newPage);
	// };

	// const handleChangeRowsPerPage = (event) => {
	// 	setRowsPerPage(+event.target.value);
	// 	setPage(0);
	// };
	return (
		<>
			<StyledTableContainer>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow sx={{ backgroundColor: "#F6F6F6" }}>
							<StyledTableCell align="left" style={{ minWidth: 170 }}>Marketpalce Order Date</StyledTableCell>
							<StyledTableCell align="left" style={{ minWidth: 170 }}>VePay Purchased Date</StyledTableCell>
							<StyledTableCell align="left" style={{ minWidth: 170 }}>Marketplace</StyledTableCell>
							<StyledTableCell align="left" style={{ minWidth: 170 }}>Region</StyledTableCell>
							<StyledTableCell align="left" style={{ minWidth: 170 }}>Store Name</StyledTableCell>
							<StyledTableCell align="left" style={{ minWidth: 170 }}>Description</StyledTableCell>
							<StyledTableCell align="left" style={{ minWidth: 170 }}>Order ID</StyledTableCell>
							<StyledTableCell align="left" style={{ minWidth: 170 }}>SKU</StyledTableCell>
							<StyledTableCell align="left" style={{ minWidth: 170 }}>Qty</StyledTableCell>
							<StyledTableCell align="left">
								<TableRow>
									<TableHead>
										<StyledTableCell align="center">
											Product Specfic Revenue & Charges
										</StyledTableCell>
									</TableHead>
									<TableBody>
										<TableRow>
											<TableHead>
												<StyledTableCell align="center" colSpan={5}>
													Sales Proceeds
												</StyledTableCell>
												<StyledTableCell align="center" colSpan={6}>
													Amazon Fee
												</StyledTableCell>
											</TableHead>
											<TableBody>
												<StyledTableCell align="left" variant="body2">
													Product Charges
												</StyledTableCell>
												<StyledTableCell align="left" variant="body2">
													Promo Rebate
												</StyledTableCell>
												<StyledTableCell align="left" variant="body2">
													Prom Rebate Delivery
												</StyledTableCell>
												<StyledTableCell align="left" variant="body2">
													Other
												</StyledTableCell>
												<StyledTableCell align="left" variant="body2">
													Sales Proceeds (Gross Sales)
												</StyledTableCell>
												<StyledTableCell align="left" variant="body2">
													Commission / Referral Fee
												</StyledTableCell>
												<StyledTableCell align="left" variant="body2">
													Commission Tax
												</StyledTableCell>
												<StyledTableCell align="left" variant="body2">
													FBA Fee
												</StyledTableCell>
												<StyledTableCell align="left" variant="body2">
													FBA Fee Tax
												</StyledTableCell>
												<StyledTableCell align="left" variant="body2">
													Delivery Chargeback
												</StyledTableCell>
												<StyledTableCell align="left" variant="body2">
													Delivery Chargeback Tax
												</StyledTableCell>
												<StyledTableCell align="left" variant="body2">
													Change to your seller account balance (Net Sale Proceeds)
												</StyledTableCell>
											</TableBody>
										</TableRow>
									</TableBody>
								</TableRow>
							</StyledTableCell>
							<StyledTableCell align="left">
								<TableRow>
									<TableHead>
										<StyledTableCell align="center">
										Marketplace Charges
										</StyledTableCell>
									</TableHead>
									<TableBody>
										<TableRow>
											<TableHead>
												<StyledTableCell align="left" colSpan={2}>
													Service Fee
												</StyledTableCell>
												<StyledTableCell align="left" colSpan={2}>
													Other
												</StyledTableCell>
												<StyledTableCell align="left">
													Carryover
												</StyledTableCell>
												<StyledTableCell align="left">
												Unavailable balance
												</StyledTableCell>
												<StyledTableCell align="left" colSpan={2}>
												Previous statement's unavailable balance
												</StyledTableCell>
											</TableHead>
											<TableBody>
												<StyledTableCell align="left" variant="body2">
												Subsription Fee
												</StyledTableCell>
												<StyledTableCell align="left" variant="body2">
												Advertising Fee
												</StyledTableCell>
												<StyledTableCell align="left" variant="body2">
												FBA Inventory Reimbursement
												</StyledTableCell>
												<StyledTableCell align="left" variant="body2">
												Advertising Fee
												</StyledTableCell>
												<StyledTableCell align="left" variant="body2">
												Failed disbursement
												</StyledTableCell>
												<StyledTableCell align="left" variant="body2">
												Current Reserve Amount
												</StyledTableCell>
												<StyledTableCell align="left" variant="body2">
												Previous Reserve Amount Balance
												</StyledTableCell>
												<StyledTableCell align="left" variant="body2">
												Total Platform Charges
												</StyledTableCell>
											</TableBody>
										</TableRow>
									</TableBody>
								</TableRow>
							</StyledTableCell>
							<StyledTableCell align="left" style={{ minWidth: 170 }}>Marketplace Account</StyledTableCell>
							<StyledTableCell align="left" style={{ minWidth: 170 }}>Marketplace Accu. Balance</StyledTableCell>
							<StyledTableCell align="left" style={{ minWidth: 170 }}>Marketplace Payment </StyledTableCell>
							<StyledTableCell align="left">
								<TableRow>
									<TableHead>
										<StyledTableCell align="center">
										
										</StyledTableCell>
									</TableHead>
									<TableBody>
										<TableRow>
											<TableHead>
												<StyledTableCell align="left">
												</StyledTableCell>
												<StyledTableCell align="left">
													
												</StyledTableCell>
												<StyledTableCell align="left">
													
												</StyledTableCell>
												<StyledTableCell align="left">
												
												</StyledTableCell>
												<StyledTableCell align="left">
												
												</StyledTableCell>
												<StyledTableCell align="left">
												</StyledTableCell>
												<StyledTableCell align="left">
													
												</StyledTableCell>
												<StyledTableCell align="left">
													
												</StyledTableCell>
												<StyledTableCell align="left">
												
												</StyledTableCell>
												<StyledTableCell align="left">
												
												</StyledTableCell>
												<StyledTableCell align="left">
												
												</StyledTableCell>
												<StyledTableCell align="left">
												
												</StyledTableCell>
											</TableHead>
											<TableBody>
												<StyledTableCell align="left" variant="body2">
												Initial Seller Funding 70% / VePay Purchase Amount 
												</StyledTableCell>
												<StyledTableCell align="left" variant="body2">
												Deduction from the funding (if any) 
												</StyledTableCell>
												<StyledTableCell align="left" variant="body2">
												Net Funding 
												</StyledTableCell>
												<StyledTableCell align="left" variant="body2">
												Seller Wallet Account 
												</StyledTableCell>
												<StyledTableCell align="left" variant="body2">
												Seller Wallet Available Balance  
												</StyledTableCell>
												<StyledTableCell align="left" variant="body2">
												VePay Revenue 
												</StyledTableCell>
												<StyledTableCell align="left" variant="body2">
												Total Payable Account  
												</StyledTableCell>
												<StyledTableCell align="left" variant="body2">
												Total Payable to the Seller  
												</StyledTableCell>
												<StyledTableCell align="left" variant="body2">
												Agency 
												</StyledTableCell>
												<StyledTableCell align="left" variant="body2">
												Gross Incentive Fee 
												</StyledTableCell>
												<StyledTableCell align="left" variant="body2">
												Adjustments 
												</StyledTableCell>
												<StyledTableCell align="left" variant="body2">
												Net Incentives  
												</StyledTableCell>
											</TableBody>
										</TableRow>
									</TableBody>
								</TableRow>
							</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
					<TableCell align="left" variant="body2">
						22/9/2024
						</TableCell>
						<TableCell align="left" variant="body2">
							1/10/2024
						</TableCell>
						<TableCell align="left" variant="body2">
							Amazon
						</TableCell>
						<TableCell align="left" variant="body2">
						Australia 
						</TableCell>
						<TableCell align="left" variant="body2">
						Hab Shifa
						</TableCell>
						<TableCell align="left" variant="body2">
						Invoice Funded 
						</TableCell>
						<TableCell align="left" variant="body2">
						250-4493703-9193436
						</TableCell>
						<TableCell align="left" variant="body2">
						B00FYGT67I
						</TableCell>
						<TableCell align="left" variant="body2">
						1
						</TableCell>
						
					

						<TableCell align="left" variant="body2">
							<TableBody>
								<TableCell align="left" variant="body2" style={{ minWidth: 170 }}>
								$39.95 
								</TableCell>
								<TableCell align="left" variant="body2" style={{ minWidth: 170 }}>
								($4.00)
								</TableCell>
								<TableCell align="left" variant="body2" style={{ minWidth: 170 }}>
									
								</TableCell>
								<TableCell align="left" variant="body2" style={{ minWidth: 170 }}>
									
								</TableCell>
								<TableCell align="left" variant="body2" style={{ minWidth: 170 }}>
								$35.95 
								</TableCell>
								<TableCell align="left" variant="body2" style={{ minWidth: 170 }}>
									
								</TableCell>
								<TableCell align="left" variant="body2" style={{ minWidth: 170 }}>
									
								</TableCell>
								<TableCell align="left" variant="body2" style={{ minWidth: 170 }}>
									
								</TableCell>
								<TableCell align="left" variant="body2" style={{ minWidth: 170 }}>
									
								</TableCell>
								<TableCell align="left" variant="body2" style={{ minWidth: 170 }}>
									
								</TableCell>
								<TableCell align="left" variant="body2" style={{ minWidth: 170 }}>
									
								</TableCell>
								<TableCell align="left" variant="body2" style={{ minWidth: 170 }}>
									
								</TableCell>
							</TableBody>
						</TableCell>

					</TableBody>
				</Table>
			</StyledTableContainer>
			{/* <TablePagination
				rowsPerPageOptions={[10, 25, 100]}
				component="div"
				count={props.ledgerDataBreakdown.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/> */}
		</>
	);
}
