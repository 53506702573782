import { BASE_URL } from "../utils/constants";
import handler from "../utils/handler";
import { clearAllLocalStorage } from '../utils/localStorageManager';

export const PostNewClient = async(value) => {
    const accessToken = sessionStorage.getItem('accessToken')
    try {
        
        const headers = {
            "Content-Type": "application/json",
        }
        
        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }

        let response = await fetch(`${BASE_URL}/core/clients`, {
            method: "POST",
            headers,
            body: JSON.stringify(value)
        });

        if (response.status === 401) {
            clearAllLocalStorage();
        };

        const data = await response.json();

        return {response, data};

    } catch (e) {
        throw e;
    }
}

export const PostNewClientAll = async(value) => {
    const accessToken = sessionStorage.getItem('accessToken')
    try {

        const headers = {
            "Content-Type": "application/json",
        }
        
        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }

        let response = await fetch(`${BASE_URL}/core/clients/all`, {
            method: "POST",
            headers,
            body: JSON.stringify(value)
        });

        if (response.status === 401) {
            clearAllLocalStorage();
        };

        const data = await response.json();

        return {response, data};

    } catch (e) {
        throw e;
    }
}

export const GetClientDetails = async(value) => {
    const accessToken = sessionStorage.getItem('accessToken')
    try {

        const headers = {
            "Content-Type": "application/json",
        }
        
        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }

        let response = await fetch(`${BASE_URL}/core/clients`, {
            method: "GET",
            headers,
        });

        if (response.status === 401) {
            clearAllLocalStorage();
        };

        let result = handler(response)

        return result;

    } catch (e) {
        throw e;
    }
}