import React from 'react'
import { useState } from 'react';
import { Box, Tab, Typography } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Overview from './Overview';
import MasterTable from './MasterTable';
import Ledgers from './Ledgers';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';


const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function Dashboard() {
	const [tabValue, setTabValue] = useState('1');

	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		handleChange();
		setOpen(false);
	};


	const handleChange = (event, newValue) => {
		setTabValue(newValue || JSON.stringify(tabValue));
	};

	return (
		<div>
			<Box sx={{ width: '100%', typography: 'body1' }}>
				<TabContext value={tabValue}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList onChange={handleChange}>
							<Tab label={<Typography variant='h3'>Overview</Typography>} value="1" sx={{ textTransform: 'capitalize' }} />
							<Tab label={<Typography variant='h3'>Master Table</Typography>} sx={{ textTransform: 'capitalize' }} onClick={handleClickOpen} />
							<Tab label={<Typography variant='h3'>Ledgers</Typography>} value="3" sx={{ textTransform: 'capitalize' }} />
						</TabList>
					</Box>
					<TabPanel value="1"><Overview /></TabPanel>
					<TabPanel value="3"><Ledgers /></TabPanel>
				</TabContext>
			</Box>
			<Dialog
				fullScreen
				open={open}
				onClose={handleClose}
				TransitionComponent={Transition}
			>
				<AppBar sx={{ position: 'relative' }}>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={handleClose}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
						<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
							Master Table
						</Typography>
					</Toolbar>
				</AppBar>
				<MasterTable />
			</Dialog>
		</div>
	)
}
