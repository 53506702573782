import { createTheme } from '@mui/material/styles';

import '@fontsource/open-sans';

export const Theme = createTheme({
  typography: {
    // h1: {
    //   fontFamily: 'DM Sans',
    //   fontStyle: 'normal',
    //   fontWeight: 700,
    //   fontSize: '3.5em', //56px
    //   lineHeight: '4.5625em' 
    // },
    h1: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 800,
      fontSize: '2.25vw', //36px, 2.25em 
    },
    h2: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '1.75vw', //28px, 1.75em
      lineHeight: '2.25vw'
    },
    h3: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '1.375vw', //22px, 1.375em
      lineHeight: '1.8125vw'
    },
    h4: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '1.125vw', //18px, 1.125em
      lineHeight: '1.4375vw'
    },
    h5: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '1.5vw', //24px, 1.5em
      lineHeight: '1.4375vw'
    },


    body1: {
      fontFamily: 'Open Sans, sans-serif',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '1vw', //16px, 1em
      lineHeight: '1.3125vw'
    },

    body2: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '0.875vw', //14px, 0.875em
      lineHeight: '1.125vw'
    },

    body3: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '0.75vw', //12px, 0.75em
      lineHeight: '1vw'
    },
    body4: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '0.62vw', //10px, 0.62em
      lineHeight: '1vw'
    },

  },

  palette: {
    neutral: {
        letter_Black: '#28282A',
        Black_Lite: '#4E5055',
        Gray_Dark: '#868B98',
        Gray_Lite: '#D6D9E1',
        White_Bg_Lite: '#F6F6F6',
        White_Bg_Dark: '#FAFAFA',
        Pure_White_Bg: '#FFFFFF'
    },

    primary: {
        main: '#093BA7',
        Blue_Dark: '#032878',
        Primary_Blue: '#093BA7',
        Primary_Lite: '#0096DA',
        Blue_Lite: '#4471D3',
        Blue_Bg_Dark: '#CAD7F4',
        Blue_Bg_Lite: '#F1F5FC'
    },

    success: {
        main: '#00AD47',
        Success_Dk: '#00AD47',
        Success_Lit: '#ECFDF3'
    },

    warning: {
        main: '#FF9F43',
        Warning_Dk: '#FF9B43',
        Warning: '#FF9F43',
        Warning_Lit: '#FFEAD6'
    },

    danger: {
        main: '#F33535',
        Danger_Dk: '#F33535',
        Danger: '#EA5455',
        Danger_Lit: '#FFD6D6'
    }
  }

});