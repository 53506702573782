import { BASE_URL } from "../utils/constants";
import handler from "../utils/handler";

export const GetMasterTransactions = async(value) => {
    const accessToken = sessionStorage.getItem('accessToken')
    try {

        const headers = {
            "Content-Type": "application/json",
        }
        
        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }
        let response = await fetch(`${BASE_URL}/dashboard/master/transactions`,{
            method: "GET",
            headers,
            body: JSON.stringify(value)
        });

        let result = handler(response)

        return result;

    } catch (e) {
        throw e;
    }
}

export const GetMasterTransactionsOrderDetails = async(value) => {
    const accessToken = sessionStorage.getItem('accessToken')
    try {

        const headers = {
            "Content-Type": "application/json",
        }

        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }

        let response = await fetch(`${BASE_URL}/dashboard/master/${value}`, {
            method: "GET",
            headers,
        });

        let result = handler(response)

        return result;

    } catch (e) {
        throw e;
    }
}

export const GetDashboardAccountBalances = async(value) => {
    const accessToken = sessionStorage.getItem('accessToken')
    try {

        const headers = {
            "Content-Type": "application/json",
        }

        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }
        
        let response = await fetch(`${BASE_URL}/dashboard/overviews/account-balances`, {
            method: "GET",
            headers,
        });

        let result = handler(response)

        return result;

    } catch (e) {
        throw e;
    }
}

export const GetDashboardAccounts = async(value) => {
    const accessToken = sessionStorage.getItem('accessToken')
    try {
        
        const headers = {
            "Content-Type": "application/json",
        }

        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }

        let response = await fetch(`${BASE_URL}/dashboard/overviews/accounts?accountType=${value}`, {
            method: "GET",
            headers,
        });

        let result = handler(response)

        return result;

    } catch (e) {
        throw e;
    }
}

export const GetAccountByName = async(value) => {
    const accessToken = sessionStorage.getItem('accessToken')
    try {
        
        const headers = {
            "Content-Type": "application/json",
        }

        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }

        let response = await fetch(`${BASE_URL}/core/account-by-name?businessId=13&account=${value}`, {
            method: "GET",
            headers,
        });

        let result = handler(response)

        return result;

    } catch (e) {
        throw e;
    }
}