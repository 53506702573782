import React from "react";
import {
    Typography,
    Box,
    Grid
} from "@mui/material";

import { formatCurrency } from "../../utils/utilityFunctions";

export default function LedgersGrouped() {

    const operationalAcc = [
        { name: 'Inventory', amount: 0.00, color: '#cfafe7' },
        { name: 'Seller Wallet', amount: 850.37, color: '#70ad47' },
        { name: 'Incentive Fee', amount: 320.03, color: '#70ad47' },
        { name: 'Agency Fee', amount: 0.00, color: '#70ad47' },
        { name: 'Rev. Realized', amount: 0.00, color: '#00b0f0' },
        { name: 'Rev. Recog.', amount: 0.00, color: '#00b0f0' },
        { name: 'Purchase', amount: 0.00, color: '#cfafe7' },
    ];

    const bankWalletAcc = [
        { name: 'Amazon Receivable', amount: 0.00, color: '#44546a' },
        { name: 'VePay Profit', amount: 0.00, color: '#44546a' },
        { name: 'VePay Client Withdrawal', amount: 0.00, color: '#44546a' },
        { name: 'VePay Capital', amount: 0.00, color: '#44546a' },
        { name: 'VePay Bank Account (RBC/Chase)', amount: 0.00, color: '#002060' },
    ];

    const controlAcc = [
        { name: 'Amazon Reserve Release Control', amount: 0.00, color: '#ffc000' },
        { name: 'Exp. Amazon Receivable Control', amount: 850.37, color: '#cfafe7' },
        { name: 'Expected Revenue Control', amount: 320.03, color: '#cfafe7' },
        { name: 'Expected Incentive Fee Payable Control', amount: 0.00, color: '#cfafe7' },
        { name: 'Expected Agency Fee Payable Control', amount: 0.00, color: '#cfafe7' },
        { name: 'Capital Recovery Control', amount: 0.00, color: '#cfafe7' },
        { name: 'Return Revenue Receivable Control', amount: 0.00, color: '#cfafe7' },
        { name: 'Remaining Amount Paid Control', amount: 0.00, color: '#cfafe7' },
        { name: 'Opening Balance Adjustment Control', amount: 0.00, color: '#cfafe7' },
    ];

    return (
        <div>
            <Box sx={{ backgroundColor: '#ffe699', p: 1, mb: 2, borderRadius: "8px" }}>
                <Typography>
                    Operational Accounts
                </Typography>
            </Box>
            <Grid container spacing={2}>
                {operationalAcc.map((row, index) => (
                    <Grid item md={3} key={index}>
                        <Box sx={{ border: "1px solid #D6D9E1", borderRadius: "8px", cursor: 'pointer' }}>
                            <Box
                                sx={{
                                    background: row.color,
                                    borderRadius: "8px 8px 0px 0px",
                                    p: 1,
                                }}
                            >
                                <Typography variant="body2">
                                    {row.name}
                                </Typography>
                            </Box>

                            <Typography
                                variant="h5"
                                color="neutral.Gray_Dark"
                                sx={{ textAlign: "center" }}
                            >
                                {formatCurrency(row.amount)}
                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
            <br></br>
            <Box sx={{ backgroundColor: '#ffe699', p: 1, mb: 2, borderRadius: "8px" }}>
                <Typography>
                    Bank/Wallet Accounts
                </Typography>
            </Box>
            <Grid container spacing={2}>
                {bankWalletAcc.map((row, index) => (
                    <Grid item md={3} key={index}>
                        <Box sx={{ border: "1px solid #D6D9E1", borderRadius: "8px", cursor: 'pointer' }}>
                            <Box
                                sx={{
                                    background: row.color,
                                    borderRadius: "8px 8px 0px 0px",
                                    p: 1,
                                }}
                            >
                                <Typography variant="body2" color="white">
                                    {row.name}
                                </Typography>
                            </Box>

                            <Typography
                                variant="h5"
                                color="neutral.Gray_Dark"
                                sx={{ textAlign: "center" }}
                            >
                                {formatCurrency(row.amount)}
                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
            <br></br>
            <Box sx={{ backgroundColor: '#ffe699', p: 1, mb: 2, borderRadius: "8px" }}>
                <Typography>
                    Control Accounts
                </Typography>
            </Box>
            <Grid container spacing={2}>
                {controlAcc.map((row, index) => (
                    <Grid item md={3} key={index}>
                        <Box sx={{ border: "1px solid #D6D9E1", borderRadius: "8px", cursor: 'pointer' }}>
                            <Box
                                sx={{
                                    background: row.color,
                                    borderRadius: "8px 8px 0px 0px",
                                    p: 1,
                                }}
                            >
                                <Typography variant="body2">
                                    {row.name}
                                </Typography>
                            </Box>

                            <Typography
                                variant="h5"
                                color="neutral.Gray_Dark"
                                sx={{ textAlign: "center" }}
                            >
                                {formatCurrency(row.amount)}
                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}
