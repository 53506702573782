import { BASE_URL } from "../utils/constants";
import handler from "../utils/handler";
import { clearAllLocalStorage } from "../utils/localStorageManager";

export const GetMasterTransactions = async(value) => {
    const accessToken = sessionStorage.getItem('accessToken')
    try {

        const headers = {
            "Content-Type": "application/json",
        }
        
        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }
        let response = await fetch(`${BASE_URL}/dashboard/master/transactions`,{
            method: "GET",
            headers,
            body: JSON.stringify(value)
        });

        if (response.status === 401) {
            clearAllLocalStorage();
        };

        let result = handler(response)

        return result;

    } catch (e) {
        throw e;
    }
}

export const GetMasterTransactionsOrderDetails = async(value) => {
    const accessToken = sessionStorage.getItem('accessToken')
    try {

        const headers = {
            "Content-Type": "application/json",
        }

        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }

        let response = await fetch(`${BASE_URL}/dashboard/master/${value}`, {
            method: "GET",
            headers,
        });

        if (response.status === 401) {
            clearAllLocalStorage();
        };

        let result = handler(response)

        return result;

    } catch (e) {
        throw e;
    }
}

export const GetDashboardAccountBalances = async(value) => {
    const accessToken = sessionStorage.getItem('accessToken')
    try {

        const headers = {
            "Content-Type": "application/json",
        }

        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }
        
        let response = await fetch(`${BASE_URL}/dashboard/overviews/account-balances`, {
            method: "GET",
            headers,
        });

        if (response.status === 401) {
            clearAllLocalStorage();
        };

        let result = handler(response)

        return result;

    } catch (e) {
        throw e;
    }
}

export const GetDashboardAccounts = async(value) => {
    const accessToken = sessionStorage.getItem('accessToken')
    try {
        
        const headers = {
            "Content-Type": "application/json",
        }

        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }

        let response = await fetch(`${BASE_URL}/dashboard/overviews/accounts?accountType=${value}`, {
            method: "GET",
            headers,
        });

        if (response.status === 401) {
            clearAllLocalStorage();
        };

        let result = handler(response)

        return result;

    } catch (e) {
        throw e;
    }
}

export const GetAccountByName = async(account, businessId) => {
    const accessToken = sessionStorage.getItem('accessToken')
    try {
        
        const headers = {
            "Content-Type": "application/json",
        }

        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }

        let response = await fetch(`${BASE_URL}/core/admin/account-by-name?businessId=${businessId}&account=${account}`, {
            method: "GET",
            headers,
        });

        if (response.status === 401) {
            clearAllLocalStorage();
        };

        let result = handler(response)

        return result;

    } catch (e) {
        throw e;
    }
}

export const DashboardCheck = async(value) => {
    const accessToken = sessionStorage.getItem('accessToken')
    try {

        const headers = {
            "Content-Type": "application/json",
        }
        
        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }
        let response = await fetch(`${BASE_URL}/admin/dashboard/check`,{
            method: "GET",
            headers,
            body: JSON.stringify(value)
        });

        if (response.status === 401) {
            clearAllLocalStorage();
        };

        let result = handler(response)

        return result;

    } catch (e) {
        throw e;
    }
}