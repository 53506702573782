import * as React from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { Stack, ThemeProvider, Avatar } from "@mui/material";
import { Theme } from "../components/Theme";
import navLogo from "../assets/navLogo.svg";
import { CloudSyncOutlined } from "@ant-design/icons";
import PersonIcon from "@mui/icons-material/Person";
import './style.css';
import { StyledTextMenu } from "../components/Menu";
// import { GetAdminData } from "../services/settings.services";


import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';


const drawerWidth = 240;

export default function Navigation(props) {
	const { window } = props;
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const navigate = useNavigate();

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	
	useEffect(() => {
		if(!sessionStorage.getItem('accessToken')){
			navigate("/signin");
		}
		(async() => {
			// try {
			//   await GetAdminData().then((data) => {
			//     if (data === null || data === undefined){ 
			//       sessionStorage.clear()
			//       navigate("/signin")
			//     }
			//   })
			// } catch (error) {
			//     sessionStorage.clear()
			//     navigate("/signin")
			//   throw error
			// }
		})()
	})

	const drawer = (
		<div
			style={{
				padding: "25% 0 10% 10%",
				backgroundColor: Theme.palette.primary.Blue_Dark,
				minHeight: "100vh",
			}}
		>
			<Box
				sx={{
					backgroundColor: Theme.palette.primary.Primary_Lite,
					minHeight: "80vh",
					borderRadius: "2em 0 0 2em",
				}}
			>
				<List>
					<Stack alignItems="center" sx={{ p: 2 }}>
						<img src={navLogo} alt="Vepay" style={{width: '5em'}}/>
					</Stack>

			 
					<NavLink
						 to="/dashboard"
						 className={({ isActive }) =>
							isActive ? 'activeText' : "inActiveText"
						}
						 >
					<ListItem>
							<ListItemText
								primary={<Typography variant="body1">Dashboard</Typography>}
							/>
					</ListItem>
					</NavLink>

					<NavLink
						 to="/client-admin"
						 className={({ isActive }) =>
							isActive ? 'activeText' : "inActiveText"
						}
						 >
					<ListItem>
				
							<ListItemText><Typography variant="body1">Client Admin</Typography></ListItemText>
						
					</ListItem>
					</NavLink>

					<NavLink
						 to="/transactions"
						 className={({ isActive }) =>
							isActive ? 'activeText' : "inActiveText"
						}
						 >
					<ListItem>
							<ListItemText
								primary={<Typography variant="body1">Transactions</Typography>}
							/>
					</ListItem>
					</NavLink>
					<NavLink
						 to="/transfers"
						 className={({ isActive }) =>
							isActive ? 'activeText' : "inActiveText"
						}
						 >
					<ListItem>
							<ListItemText
								primary={<Typography variant="body1">Transfers</Typography>}
							/>
					</ListItem>
					</NavLink>
					<NavLink
						 to="/support"
						 className={({ isActive }) =>
							isActive ? 'activeText' : "inActiveText"
						}
						 >
					<ListItem>
							<ListItemText
								primary={<Typography variant="body1">Support</Typography>}
							/>
					</ListItem>
					</NavLink>
					<NavLink
						 to="/settings"
						 className={({ isActive }) =>
							isActive ? 'activeText' : "inActiveText"
						}
						 >
					<ListItem>
							<ListItemText
								primary={<Typography variant="body1">Settings</Typography>}
							/>
					</ListItem>
					</NavLink>
				</List>
			</Box>
		</div>
	);

	const container =
		window !== undefined ? () => window().document.body : undefined;

	return (
		<Box sx={{ display: "flex" }}>
			<ThemeProvider theme={Theme}>
				<CssBaseline />
				<AppBar
					position="fixed"
					sx={{
						backgroundColor: "#FFFFFF",
						boxShadow: "0px 2px 8px rgba(13, 51, 115, 0.1)",
						width: { sm: `calc(100% - ${drawerWidth}px)` },
						ml: { sm: `${drawerWidth}px` },
					}}
				>
					<Toolbar>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							edge="start"
							onClick={handleDrawerToggle}
							sx={{ mr: 2, display: { sm: "none" } }}
						>
							<MenuIcon />
						</IconButton>
						<Stack direction="row" justifyContent="space-between" width="100%" alignItems="center">
							<Stack direction="row" spacing={1}>
								<CloudSyncOutlined
									style={{ color: Theme.palette.neutral.Black_Lite }}
								/>
								<Typography
									variant="body2"
									noWrap
									component="div"
									sx={{ color: "neutral.Black_Lite" }}
								>
									Last synced: 10.32 PT
								</Typography>
							</Stack>
							<Stack direction="row" spacing={1} alignItems="center">
								{/* account page coding menu */}
								<Box sx={{ display: 'flex-start', alignItems: 'center', textAlign: 'center' }}>
				<Tooltip title="Account settings">
					<IconButton
						onClick={handleClick}
						size="small"
						sx={{ ml: 2 }}
						aria-controls={open ? 'account-menu' : undefined}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
					>
						<Typography
									variant="body2"
									noWrap
									component="div"
									sx={{ color: "neutral.Black_Lite"}}
								>
									Mafaz J &nbsp;
								</Typography>
								<Avatar style={{backgroundColor: Theme.palette.primary.Primary_Blue, width: 30, height: 30 }}>
									<PersonIcon />
								</Avatar>
					</IconButton>
				</Tooltip>
			</Box>
			<StyledTextMenu className="account"
				anchorEl={anchorEl}
				id="account-menu"
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				PaperProps={{
					elevation: 0,
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				<Stack direction="row" justifyContent="space-between" alignItems="center" sx={{p:2}}>
					<Typography variant="h3" fontWeight="600">Account</Typography>
					<Typography variant="body3">Super Admin</Typography>
			 	</Stack>
					<Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
				<MenuItem>
					<Avatar/>
				</MenuItem>
				<Stack sx={{pr:8, pb:2,pt:2}} justifyContent="space-between" >
					<Typography variant="body2" fontWeight="600"> Mafaz Jaufer</Typography>
					<Typography variant="body2" fontWeight="400"> mafaz@vepay.com</Typography>
					</Stack>
				</Stack>
				<Divider />
				<div className="hover">
					<MenuItem sx={{pl:3,pt:3,pb:2}}>
						<a href="/settings" style={{ textDecoration: 'none',color: "#28282A"}}> 
							Account Settings
						</a>
					</MenuItem>
				</div>
				<div className="hover">
					<MenuItem  sx={{pl:3,pt:3,pb:4}}>
						<a href="/support" style={{ textDecoration: 'none',color: "#28282A"}}> 
							Help
						</a>
					</MenuItem>
				</div>
				<Divider />
				<div className="hover">
					<MenuItem sx={{pl:3,pt:3,pb:2,}}>
						<a href="/signin" style={{ textDecoration: 'none',color: "#28282A"}}> 
							Logout
						</a>
					</MenuItem>
				</div>
			</StyledTextMenu>
								
							</Stack>
						</Stack>
					</Toolbar>
				</AppBar>
				<Box
					component="nav"
					sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
				>
					{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
					<Drawer
						container={container}
						variant="temporary"
						open={mobileOpen}
						onClose={handleDrawerToggle}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
						sx={{
							display: { xs: "block", sm: "none" },
							"& .MuiDrawer-paper": {
								boxSizing: "border-box",
								width: drawerWidth,
							},
						}}
					>
						{drawer}
					</Drawer>
					<Drawer
						variant="permanent"
						sx={{
							display: { xs: "none", sm: "block" },
							"& .MuiDrawer-paper": {
								boxSizing: "border-box",
								width: drawerWidth,
							},
						}}
						open
					>
						{drawer}
					</Drawer>
				</Box>
				<Box
					component="main"
					sx={{
						flexGrow: 1,
						p: 2,
						width: { sm: `calc(100% - ${drawerWidth}px)` },
					}}
				>
					<Toolbar />
					<Outlet />
				</Box>
			</ThemeProvider>
		</Box>
	);
}

Navigation.propTypes = {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window: PropTypes.func,
};
